.js-off-canvas-overlay {
    background: rgba(0, 0, 0, .35);
}

.off-canvas.position-left {
    background: color(base, Secondary);
    &.is-open {
        width: 85%;
        overflow: hidden;
        overflow-y: auto;
        @include breakpoint(medium) {
            max-width: 35rem;
        }
    }
    button.close-button {
        margin: 0 0 0 auto;
        position: static;
        height: 4.6rem;
        line-height: 4.6rem;
        span {
            color: color(base, Primary);
            font-size: 4rem;
            height: 4.6rem;
            line-height: 3.6rem;
        }
        @include breakpoint(medium) {
            &:hover span {
                color: color(base, PrimaryHover);
            }
        }
    }
    .modal__header {
        position: relative;
        display: flex;
        align-items: center;
        padding: 0 1rem 0 .9375em;
        border-bottom: .1rem solid color(base, Primary);
        height: 6.5rem;
        flex: 0 0 6.5rem;
        > span.title, a.title {
            @include head-font;
            display: block;
            color: color(base, Primary);
            text-transform: uppercase;
            font-size: 1.8rem;
            line-height: 4rem;
            padding: 0 0 .5rem;
        }
        @include breakpoint(medium) {
            > a.title {
                &:hover {
                    color: color(base, PrimaryHover);
                }
            }
        }
    }
    nav {
        a, button {
            color: color(base, Primary);
            font-size: 1.4rem;
            font-weight: normal;
            border-bottom: .1rem solid color(base, Primary);
            text-align: left;
            padding: 1rem .9375rem;
            line-height: 2;
        }
        > ul > li {
            &.is-drilldown-submenu-parent > a {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, Primary)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M8.25 4.5l7.5 7.5-7.5 7.5' /%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                background-position: right 1rem center;
                background-size: 2rem;
            }
            > ul.nested {
                background: color(base, Secondary);
                height: 99vh;
                li {
                    &.js-drilldown-back {
                        button {
                            padding: 0 0.9375em;
                            width: 100%;
                            line-height: 4.8rem;
                            font-size: 1.4rem;
                        }
                    }
                    &.is-drilldown-submenu-parent > a {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, Primary)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M8.25 4.5l7.5 7.5-7.5 7.5' /%3E%3C/svg%3E%0A");
                        background-repeat: no-repeat;
                        background-position: right 1rem center;
                        background-size: 2rem;
                    }
                    ul {
                        background: color(base, Secondary);
                    }
                }
            }
        }
    }
    ._menu {
        padding: 0 1.5rem;
    }
    .language__select {
        border-bottom: .1rem solid color(base, Primary);
        display: flex;
        flex-wrap: wrap;
        max-height: 4.2rem;
        overflow: hidden;
        li {
            flex: 1;
            a {
                display: flex;
                justify-content: center;
                border: none;
                padding: 1rem;
                &.--active {
                    background-color: color(gray, Gray-100);
                    pointer-events: none;
                    border-bottom: 1px solid color(base, White);
                }
            }
            &:not(:last-child) a {
                border-right: .1rem solid color(base, Primary);
            }
        }
    }
}
