.catalog-category-page {
    @include breakpoint(small only) {
        .breadcrumbs {
            display: none;
        }
    }
    .category-container {
        > .grid-container {
            position: relative;
        }
        .--header {
            display: flex;
            align-items: center;
            gap: .5rem;
            flex-wrap: wrap;
            @include breakpoint(small only) {
                margin: 3rem 0;
                justify-content: center;
            }
            h1 {
                text-transform: uppercase;
                color: color(base, Secondary);
                font-size: 2rem;
                overflow-wrap: anywhere;
                @include breakpoint(medium) {
                    font-size: 1.8rem;
                }
            }
            .catalog__product-count {
                font-family: $secondary-font;
                font-size: 1.8rem;
            }
        }
        .toolbar__container {
            @include breakpoint(medium) {
                position: absolute;
                right: 1rem;
                bottom: calc(100% + 2rem);
                z-index: 2;
            }
            .catalog__toolbar {
                @include breakpoint(small only) {
                    height: 5.5rem;
                    .--container {
                        margin: 0 0 2rem;
                        display: flex;
                        gap: 2rem;
                        > div {
                            flex: 1;
                            button {
                                height: 3.5rem;
                                width: 100%;
                                display: flex;
                                align-items: center;
                                gap: .5rem;
                                justify-content: center;
                                span {
                                    line-height: 1.2;
                                }
                            }
                        }
                        &._sticky {
                            position: fixed;
                            top: 6.5rem;
                            z-index: 4;
                            left: 0;
                            right: 0;
                            background: White;
                            padding: 1rem;
                            border-bottom: .1rem solid color(gray, Gray-100);
                        }
                    }
                }
                .catalog-category__sorter {
                    display: flex;
                    align-items: center;
                    margin-left: auto;
                    gap: 1rem;
                    label {
                        font-size: 1.4rem;
                        font-weight: 100;
                    }
                    select {
                        flex: 1;
                    }
                }
            }
        }
        .category__description {
            margin: 0 0 4rem;
            h1, h2, h3 {
                font-size: 3.2rem;
                text-transform: uppercase;
                margin: 0 0 1rem;
            }
            h2, h3 {
                font-size: 2.8rem;
            }
            p {
                font-size: 1.6rem;
                font-weight: 100;
                &:not(:last-of-type) {
                    margin: 0 0 2rem;
                }
            }
            a {
                font-size: 1.6rem;
                font-weight: 100;
                text-decoration: underline;
            }
            strong {
                font-weight: 700;
                font-size: 1.6rem;
            }
            ul {
                list-style: disc inside;
                margin: 0 0 2rem;
                li {
                    font-size: 1.2rem;
                }
            }
        }
        .--actions {
            margin: 2rem 0 0;
            button {
                cursor: pointer;
                font-size: 1.4rem;
                font-weight: 600;
                @include hover() {
                    text-decoration: underline;
                }
            }
        }
    }
    .hero-container {
        .--content {
            position: absolute;
            bottom: 10rem;
            left: 0;
            right: 0;
            span {
                display: block;
                color: color(base, Primary);
                font-size: clamp(1.8rem, 4.4vw, 7.2rem);
                text-transform: uppercase;
                font-family: $secondary-font;
            }
        }
    }
    .recently__viewed {
        dd {
            a {
                display: flex;
                align-items: center;
                gap: 1rem;
                &:not(:last-of-type) {
                    margin: 0 0 1rem;
                }
                @include hover() {
                    .--holder h4 {
                        text-decoration: underline;
                    }
                }
                img {
                    aspect-ratio: 63/90;
                    object-fit: scale-down;
                    flex: 0 0 6.3rem;
                }
                .--holder {
                    padding: 0 1rem 0 0;
                    h4 {
                        font-size: 1.4rem;
                        line-height: 1.8rem;
                        font-weight: 100;
                        font-family: $default-font;
                    }
                    .price__holder {
                        span {
                            font-size: 1.4rem;
                            line-height: 2.2rem;
                            font-weight: 700;
                            &.--old {
                                text-decoration: line-through;
                                color: color(gray, Gray-400);
                                font-weight: 400;
                            }
                        }
                    }
                }
            }
        }
    }
}
