.customer-account {
    padding: 3rem 0;
    @include breakpoint(small only) {
        padding: 0;
    }
    .messages {
        margin: 0 0 2rem;
    }
    .margin__bottom {
        margin-bottom: 2rem;
        align-items: center;
        flex-wrap: wrap;
        gap: 1rem;
        h1 {
            margin: 0;
        }
    }
    h1, h2, h3 {
        font-size: 2rem;
        margin: 0 0 1.5rem;
        text-transform: uppercase;
        span {
            font-size: 2rem;
            text-transform: uppercase;
            font-family: $secondary-font;
        }
    }
    @include breakpoint(small only) {
        h1 {
            margin: 2rem 0;
        }
    }
    h3 {
        font-size: 1.6rem;
        text-transform: initial;
    }
    .breadcrumbs {
        display: none;
    }
    .customer__account-navigation_toggle {
        @include default-button;
        background-repeat: no-repeat;
        background-position: left .8rem center;
        background-size: 1.6rem;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-menu-2' width='20' height='20' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, Primary)}' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cline x1='4' y1='6' x2='20' y2='6' /%3E%3Cline x1='4' y1='12' x2='20' y2='12' /%3E%3Cline x1='4' y1='18' x2='20' y2='18' /%3E%3C/svg%3E");
        margin: 2rem 0;
        &._secondary {
            padding: 0 1rem 0 3rem;
        }
    }
    .customer__account-navigation {
        @include breakpoint(medium) {
            background: color(gray, Gray-100);
            padding: 1.5rem;
            max-width: 75%;
        }
        @include breakpoint(small only) {
            nav {
                height: auto;
                display: block;
                ul li {
                    a {
                        display: block;
                        color: color(base, Primary);
                        font-size: 1.4rem;
                        line-height: 5rem;
                        padding: 0 .9375em;
                    }
                }
            }
        }
        h3 {
            margin: 0 0 1rem;
        }
        ul li {
            @include breakpoint(medium) {
                a {
                    font-size: 1.4rem;
                    display: block;
                    line-height: 2.8rem;
                    color: color(base, Black);
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            &._active {
                a {
                    font-weight: 700;
                    pointer-events: none;
                }
            }
        }
    }
    ._date {
        margin-left: auto;
        line-height: 3.4rem;
        font-size: 1.2rem;
        @include breakpoint(small only) {
            line-height: normal;
            margin: 20px 0 0 auto;
        }
    }
    .customer__block {
        margin: 0 0 2rem;
        .a-center {
            text-align: center;
        }
        .a-right {
            text-align: right;
        }
        table {
            width: 100%;
            margin: 0 0 2rem;
            tr {
                &:nth-child(even) {
                    background: #FAFAFA;
                }
                th {
                    text-align: left;
                    font-weight: 500;
                }
                th, td {
                    padding: .4rem .2rem;
                    strong {
                        font-weight: normal;
                    }
                    ul li {
                        font-size: 1.4rem;
                        strong {
                            font-weight: normal;
                        }
                    }
                    a:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    .order__totals {
        ul {
            margin-left: auto;
            li {
                display: flex;
                span, strong {
                    display: block;
                    flex: 1;
                    text-align: right;
                    font-size: 1.4rem;
                    line-height: 2.4rem;
                    &:first-child {
                        width: 14rem;
                    }
                }
                strong {
                    font-size: 1.6rem;
                    font-weight: normal;
                }
            }
        }
    }

    .customer__account_block {
        &._login {
            border: .1rem solid color(gray, Gray-200);
            padding: 2rem;
            margin-bottom: 1.5rem;
            ._forget {
                font-size: 1.3rem;
                font-weight: normal;
                margin-left: 1rem;
            }
        }
        ._usp {
            margin: 2rem 0;
            line-height: 2rem;
            font-size: 1.4rem;
            h3 {
                margin: 0 0 1rem;
            }
            p {
                line-height: 2rem;
                font-size: 1.4rem;
            }
            li {
                line-height: 3rem;
                color: color(base, Black);
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-check' width='20' height='20' viewBox='0 0 24 24' stroke-width='3' stroke='#{color(alert, Success)}' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M5 12l5 5l10 -10' /%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: left center;
                padding-left: 2.5rem;
                font-size: 1.3rem;
                @include breakpoint(small only) {
                    font-size: 1.2rem;
                }
            }
        }
    }
    ._intro {
        margin: 0 0 2rem;
    }
    ._header {
        display: flex;
        align-items: center;
        margin: 1rem 0;
        a {
            margin-left: auto;
            font-size: 1.3rem;
            text-decoration: underline;
        }
        h2, h3 {
            margin: 0;
        }
    }
    .address__block {
        background: color(gray, Gray-100);
        padding: 1rem;
        margin: 0 0 1.5rem;
        > span {
            font-size: 1.4rem;
            line-height: 2.4rem;
        }
        address {
            font-size: 1.4rem;
            line-height: 2.4rem;
        }
        > a {
            display: inline-block;
            font-size: 1.3rem;
            margin-top: 1rem;
            &:hover {
                text-decoration: underline;
            }
        }
        ._actions {
            display: flex;
            margin: 1rem 0 0;
            a {
                display: block;
                font-size: 1.3rem;
                margin-right: 1rem;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    .checkout__address-autocomplete {
        .--flex {
            gap: 1rem;
        }
        .checkout__address-autocomplete_result {
            @include autocomplete;
            address {
                font-size: 1.4rem;
                margin-top: 1rem;
                min-width: 50%;
            }
        }
    }
    .customer-account_box {
        .field__container {
            &.--flex {
                align-items: center;
                input[type=radio] {
                    margin: .1rem .5rem 0 0;
                }
                label {
                    margin: 0 2rem 0 0;
                    cursor: pointer;
                    font-size: 1.4rem;
                }
            }
        }
    }
    .wishlist__container {
        .--content {
            margin: 2rem 0;
            text-align: center;
            @include breakpoint(medium) {
                margin: 2rem 0 0;
            }
            p {
                font-size: 1.6rem;
                font-weight: 100;
                margin: 0 0 2rem;
            }
        }
    }
}
