.error-page_body {
    .breadcrumbs {
        display: none;
    }
    .content.std {
        strong {
            font-weight: 600;
            margin: 0 0 .5rem;
            display: block;
        }
    }
}

.cms-page {
    .content {
        margin: 3rem 0 0;
        h1 {
            margin: 0 0 2rem;
            font-size: 2.2rem;
        }
        h2 {
            margin: 0 0 2rem;
            font-size: 2rem;
        }
        h3 {
            margin: 0 0 2rem;
            font-size: 1.8rem;
        }
        h4 {
            margin: 0 0 2rem;
            font-size: 1.6rem;
        }
        p {
            font-size: 1.5rem;
            line-height: 2.4rem;
            &:not(:last-of-type) {
                margin: 0 0 2rem;
            }
        }
        a {
            text-decoration: underline;
            font-size: 1.5rem;
            line-height: 2.4rem;
            &:hover {
                color: color(base, SecondaryHover);
            }
        }
        ul, ol {
            list-style: disc inside;
            margin: 0 0 2rem;
            font-size: 1.5rem;
            line-height: 2.4rem;
        }
        ol {
            list-style: decimal inside;
        }
        table {
            width: 100%;
            margin: 0 0 2rem;
            background: color(base, White);
            tr {
                &:nth-child(even) {
                    background: color(gray, Gray-100);
                }
                th, td {
                    text-align: left;
                    border: .1rem solid color(gray, Gray-200);
                    border-collapse: collapse;
                    padding: .4rem .6rem;
                }
            }
        }
    }
}
