@font-face {
    font-family: 'Laslo Wide';
    src: url('../assets/fonts/LasloWide-Black.woff2') format('woff2'),
    url('../assets/fonts/LasloWide-Black.woff') format('woff'),
    url('../assets/fonts/LasloWide-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: fallback;
    ascent-override: 90%;
}

@font-face {
    font-family: 'Laslo';
    src: url('../assets/fonts/Laslo-Thin.woff2') format('woff2'),
    url('../assets/fonts/Laslo-Thin.woff') format('woff'),
    url('../assets/fonts/Laslo-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: fallback;
    ascent-override: 90%;
}

@font-face {
    font-family: 'Laslo';
    src: url('../assets/fonts/Laslo-Light.woff2') format('woff2'),
    url('../assets/fonts/Laslo-Light.woff') format('woff'),
    url('../assets/fonts/Laslo-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: fallback;
    ascent-override: 90%;
}

@font-face {
    font-family: 'Laslo';
    src: url('../assets/fonts/Laslo-Regular.woff2') format('woff2'),
    url('../assets/fonts/Laslo-Regular.woff') format('woff'),
    url('../assets/fonts/Laslo-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
    ascent-override: 90%;
}


@font-face {
    font-family: 'Laslo';
    src: url('../assets/fonts/Laslo-Bold.woff2') format('woff2'),
    url('../assets/fonts/Laslo-Bold.woff') format('woff'),
    url('../assets/fonts/Laslo-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: fallback;
    ascent-override: 90%;
}

@font-face {
    font-family: 'Laslo';
    src: url('../assets/fonts/Laslo-Black.woff2') format('woff2'),
    url('../assets/fonts/Laslo-Black.woff') format('woff'),
    url('../assets/fonts/Laslo-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: fallback;
    ascent-override: 90%;
}