.catalog__category-filter {
    @include breakpoint(medium) {
        border-top: .1rem solid color(gray, Gray-200);
        margin: 2rem 0;
    }
    .--sub-categories {
        @include breakpoint(medium) {
            border-bottom: .1rem solid color(gray, Gray-200);
        }
        padding: .5rem 0;
        > span {
            display: block;
            font-size: 1.6rem;
            text-transform: uppercase;
            line-height: 3.8rem;
            color: color(base, Secondary);
            font-weight: 900;
        }
        ol li {
            a {
                font-size: 1.4rem;
                line-height: 3rem;
                font-weight: 500;
                color: color(base, Primary);
                @include breakpoint(medium) {
                    color: color(base, Secondary);
                    &:hover {
                        color: color(base, SecondaryHover);
                    }
                }
            }
        }
    }
    dl {
        border-bottom: .1rem solid color(base, Primary);
        @include breakpoint(medium) {
            padding: .5rem 0;
            border-color: color(gray, Gray-200);
        }
        dt {
            font-size: 1.6rem;
            text-transform: uppercase;
            line-height: 4.2rem;
            color: color(base, Secondary);
            background-image: url("data:image/svg+xml,%0A%3Csvg width='11px' height='9px' viewBox='0 0 11 9' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline id='Stroke-1' stroke='#{color(base, Secondary)}' stroke-width='2' transform='translate(5.306354, 4.330010) rotate(-270.000000) translate(-5.306354, -4.330010) ' points='2.44757827 -0.169989964 8.16998996 4.3284815 2.44271724 8.83001004'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: right center;
            cursor: pointer;
            font-weight: 900;
            &._visible {
                background-image: url("data:image/svg+xml,%0A%3Csvg width='11px' height='9px' viewBox='0 0 11 9' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline id='Stroke-1' stroke='#{color(base, Secondary)}' stroke-width='2' transform='translate(5.306354, 5.330010) rotate(-90.000000) translate(-5.306354, -5.330010) ' points='2.44757827 0.830010036 8.16998996 5.3284815 2.44271724 9.83001004'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
            }
            @include breakpoint(small only) {
                color: color(base, Primary);
                padding: 0 1.5rem;
                background-position: right 1.5rem center;
                font-size: 1.4rem;
                line-height: 5rem;
                background-image: url("data:image/svg+xml,%0A%3Csvg width='11px' height='9px' viewBox='0 0 11 9' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline id='Stroke-1' stroke='#{color(base, Primary)}' stroke-width='2' transform='translate(5.306354, 4.330010) rotate(-270.000000) translate(-5.306354, -4.330010) ' points='2.44757827 -0.169989964 8.16998996 4.3284815 2.44271724 8.83001004'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
                &._visible {
                    background-image: url("data:image/svg+xml,%0A%3Csvg width='11px' height='9px' viewBox='0 0 11 9' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline id='Stroke-1' stroke='#{color(base, Primary)}' stroke-width='2' transform='translate(5.306354, 5.330010) rotate(-90.000000) translate(-5.306354, -5.330010) ' points='2.44757827 0.830010036 8.16998996 5.3284815 2.44271724 9.83001004'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
                }
            }
        }
        dd {
            padding-bottom: 1rem;
            @include breakpoint(small only) {
                padding: 1rem;
                background: #22253a;
            }
            ol {
                li label {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    gap: 1rem;
                    ._checkbox {
                        border-radius: .2rem;
                        display: block;
                        width: 1.6rem;
                        height: 1.6rem;
                        border: .1rem solid color(base, Secondary);
                        background-size: 1rem;
                        background-repeat: no-repeat;
                        background-position: center;
                        @include breakpoint(small only) {
                            border-color: color(base, Primary);
                        }
                    }
                    ._title {
                        flex: 1;
                        font-size: 1.4rem;
                        line-height: 3rem;
                        font-weight: 100;
                        color: color(base, Secondary);
                        display: flex;
                        align-items: center;
                        gap: .5rem;
                        @include breakpoint(small only) {
                            color: color(base, Primary);
                            font-size: 1.6rem;
                            line-height: 4rem;
                        }
                    }
                    ._count {
                        font-size: 1.2rem;
                        @include breakpoint(small only) {
                            color: color(base, Primary);
                            font-size: 1rem;
                            line-height: 4rem;
                        }
                    }
                    &._active {
                        ._checkbox {
                            background-image: url("data:image/svg+xml,%0A%3Csvg width='16px' height='14px' viewBox='0 0 16 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline id='Stroke-1' stroke='%23110047' stroke-width='3' points='1 6.9031134 5.25802431 11.1611377 15 2'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
                            background-repeat: no-repeat;
                            background-position: center;
                            border-color: color(base, Secondary);
                            background-size: 85%;
                            @include breakpoint(small only) {
                                background-image: url("data:image/svg+xml,%0A%3Csvg width='16px' height='14px' viewBox='0 0 16 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline id='Stroke-1' stroke='#{color(base, Primary)}' stroke-width='3' points='1 6.9031134 5.25802431 11.1611377 15 2'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
                                border-color: color(base, Primary);
                            }
                        }
                    }
                    @include hover() {
                        &:not(._active) {
                            ._checkbox {
                                border-color: color(base, SecondaryHover);
                                background-image: url("data:image/svg+xml,%0A%3Csvg width='16px' height='14px' viewBox='0 0 16 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline id='Stroke-1' stroke='#{color(gray, Gray-200)}' stroke-width='2' points='1 6.9031134 5.25802431 11.1611377 15 2'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
                            }
                        }
                        ._title {
                            color: color(base, SecondaryHover);
                        }
                    }
                }
                &.--swatch {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 1.4rem;
                    padding: 1rem 0;
                    li label {
                        display: block;
                        width: 1.6rem;
                        height: 1.6rem;
                        border: .1rem solid color(gray, Gray-200);
                        @include breakpoint(small only) {
                            width: 3.4rem;
                            height: 3.4rem;
                        }
                        &._active {
                            background-image: url("data:image/svg+xml,%0A%3Csvg width='16px' height='14px' viewBox='0 0 16 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline id='Stroke-1' stroke='#{color(gray, Gray-100)}' stroke-width='3' points='1 6.9031134 5.25802431 11.1611377 15 2'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
                            background-repeat: no-repeat;
                            background-position: center;
                            border-color: color(base, Secondary);
                            background-size: 85%;
                            &.--light {
                                background-image: url("data:image/svg+xml,%0A%3Csvg width='16px' height='14px' viewBox='0 0 16 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline id='Stroke-1' stroke='%23110047' stroke-width='3' points='1 6.9031134 5.25802431 11.1611377 15 2'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
                            }
                        }
                        @include breakpoint(medium) {
                            &:hover {
                                ._checkbox {
                                    border-color: color(gray, Gray-300);
                                }
                            }
                        }
                    }
                }
            }
            .price__filter {
                padding: 1rem 0 0;
                .vue-slider {
                    margin: 0 .7rem;
                    .vue-slider-rail {
                        background: color(gray, Gray-200);
                        border-radius: 0;
                        .vue-slider-process {
                            background: color(base, Secondary);
                            @include breakpoint(small only) {
                                background: color(base, Primary);
                            }
                        }
                        .vue-slider-dot {
                            .vue-slider-dot-handle {
                                box-shadow: none;
                                background: color(base, white);
                                border: .3rem solid color(base, Secondary);
                                @include breakpoint(small only) {
                                    border-color: color(base, Primary);
                                    background: color(base, Primary);
                                }
                            }
                        }
                    }
                    .vue-slider-dot-tooltip-inner {
                        background: color(base, Secondary);
                        border-color: color(base, Secondary);
                        .vue-slider-dot-tooltip-text {
                            color: color(base, White);
                            font-weight: 900;
                            font-size: 1.2rem;
                        }
                    }
                }
                .custom__controls {
                    margin: 2rem 0 1rem;
                    > span {
                        font-weight: 700;
                        @include breakpoint(small only) {
                            color: color(base, Primary);
                        }
                    }
                }
            }
        }
    }
}

@include breakpoint(medium) {
    .off-canvas.in-canvas-for-medium {
        background: color(base, White);
    }
}

@include breakpoint(small only) {
    .catalog__category-filter {
        height: 100%;
        section {
            height: 100%;
            display: flex;
            flex-direction: column;
            .modal__content {
                height: 100%;
                overflow-y: auto;
                overflow-scrolling: touch;
            }
            .modal__footer {
                padding: 2rem;
                border-top: .1rem solid color(base, Primary);
                display: flex;
                justify-content: space-evenly;
                button {
                    padding: 0;
                    &.--transparent {
                        padding: .5rem 1.5rem;
                        font-family: $default-font;
                        background: transparent;
                        border: .2rem solid color(base, Primary);
                        color: color(base, Primary);
                        font-weight: 600;
                    }
                    span {
                        display: block;
                        font-size: 1.4rem;
                        line-height: 2.8rem;
                        font-weight: 600;
                        text-transform: uppercase;
                        padding: .5rem 1.5rem;
                    }
                }
            }
        }
    }
}
