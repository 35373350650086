@keyframes marquee {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-100%);
    }
}

@keyframes wishlist-animation {
    0%   {transform: scale(1);}
    25%  {transform: scale(.5);}
    50%  {transform: scale(1.2);}
    100% {transform: scale(1);}
}
