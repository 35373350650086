@mixin clearfix {
    &::after {
        content: '';
        display: table;
        clear: both;
    }
}

@mixin transition {
    transition: all .3s ease;
}

@mixin rating {
    ._rating {
        display: block;
        height: 1.1rem;
        width: 7.2rem;
        background-image: url("data:image/svg+xml,%0A%3Csvg width='124px' height='19px' viewBox='0 0 124 19' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group-9' stroke='%23EFDD2D'%3E%3Cpath d='M10.1390781,1.11640816 L12.9422378,6.71253487 L19.1913428,7.60683873 L14.670441,11.9495852 L15.7387275,18.0919463 L10.1390965,15.1914112 L4.53856869,18.0919454 L5.60775346,11.9495854 L1.08595443,7.6068386 L7.33591555,6.7125406 L10.1390781,1.11640816 Z' id='Fill-1'%3E%3C/path%3E%3Cpath d='M37.2123759,1.11626498 L40.0156044,6.71252914 L46.2637258,7.60682058 L41.7427722,11.9496167 L42.8119373,18.0918636 L37.2124845,15.1914208 L31.6110782,18.0919445 L32.6811613,11.9495857 L28.1584462,7.60682034 L34.4082391,6.7125464 L37.2123759,1.11626498 Z' id='Fill-2'%3E%3C/path%3E%3Cpath d='M63.8150599,1.11640816 L66.6182195,6.71253487 L72.8673246,7.60683873 L68.3464228,11.9495852 L69.4147093,18.0919463 L63.8150783,15.1914112 L58.2145505,18.0919454 L59.2837352,11.9495854 L54.7619362,7.6068386 L61.0118973,6.7125406 L63.8150599,1.11640816 Z' id='Fill-3'%3E%3C/path%3E%3Cg id='Group-8' transform='translate(78.172151, 0.000297)' stroke-width='0.806'%3E%3Cpath d='M10.1390781,0.899824978 L13.0062329,6.62370918 L19.4028769,7.53899584 L14.7748163,11.9837965 L15.8687002,18.2680503 L10.1390781,15.3006578 L4.409456,18.2680503 L5.50333986,11.9837965 L0.875279272,7.53899584 L7.27192332,6.62370918 L10.1390781,0.899824978 Z' id='Stroke-4'%3E%3C/path%3E%3Cpath d='M35.6887706,0.899824978 L38.5559254,6.62370918 L44.9525694,7.53899584 L40.3245088,11.9837965 L41.4183927,18.2680503 L35.6887706,15.3006578 L29.9591485,18.2680503 L31.0530324,11.9837965 L26.4249718,7.53899584 L32.8216158,6.62370918 L35.6887706,0.899824978 Z' id='Stroke-6'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        background-size: cover;
        @include breakpoint(medium) {
            height: 1.9rem;
            width: 12.4rem;
        }
        ._rating__fill {
            display: block;
            height: 1.1rem;
            background-image: url("data:image/svg+xml,%0A%3Csvg width='124px' height='19px' viewBox='0 0 124 19' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group-9' fill='%23EFDD2D'%3E%3Cpolygon id='Fill-1' points='7.00605872 6.25464669 0 7.25713362 5.06953904 12.1259313 3.87303935 18.9997028 10.1390781 15.7544981 16.4041114 18.9997028 15.2086171 12.1259313 20.2771507 7.25713362 13.2720975 6.25464669 10.1390781 0'%3E%3C/polygon%3E%3Cpolygon id='Fill-2' points='34.0784228 6.25464669 27.0723641 7.25713362 32.1429086 12.1259313 30.9454034 18.9997028 37.2124476 15.7544981 43.4774809 18.9997028 42.2809812 12.1259313 47.3495148 7.25713362 40.345467 6.25464669 37.2124476 0'%3E%3C/polygon%3E%3Cpolygon id='Fill-3' points='60.6820405 6.25464669 53.6759818 7.25713362 58.7455208 12.1259313 57.5490211 18.9997028 63.8150599 15.7544981 70.0800932 18.9997028 68.8845989 12.1259313 73.9531325 7.25713362 66.9480792 6.25464669 63.8150599 0'%3E%3C/polygon%3E%3Cg id='Group-8' transform='translate(78.172151, 0.000297)'%3E%3Cpolygon id='Stroke-4' points='10.1390781 0 13.2720975 6.25464669 20.2781562 7.25713362 15.2086171 12.1259313 16.4051168 18.9997028 10.1390781 15.7544981 3.87303935 18.9997028 5.06953904 12.1259313 0 7.25713362 7.00605872 6.25464669'%3E%3C/polygon%3E%3Cpolygon id='Stroke-6' points='35.6887706 0 38.82179 6.25464669 45.8278487 7.25713362 40.7583097 12.1259313 41.9548094 18.9997028 35.6887706 15.7544981 29.4227319 18.9997028 30.6192316 12.1259313 25.5496925 7.25713362 32.5557512 6.25464669'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
            background-size: cover;
            @include breakpoint(medium) {
                height: 1.9rem;
            }
        }
    }
}

@mixin head-font {
    font-family: $secondary-font;
}

@mixin shadow($type: box) {
    @if $type == box {
        box-shadow: 0 0 1.5rem 0 rgba(0, 0, 0, 0.5);
    } @else {
        text-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.5);
    }
}

@mixin block-spacing {
    margin: 3rem 0 0;
    @include breakpoint(medium) {
        margin: 8rem 0 0;
        @include breakpoint(portrait only) {
            margin: 3rem 0 0;
        }
    }
}

@mixin messages($type) {
    @if ($type == "warning") {
        background-color: #fcf8e3;
        border-color: #faf2cc;
        color: color(alert, Warning);
        a {
            color: color(alert, Warning);
            text-decoration: underline;
        }
        strong {
            color: #8a6d3b;
            font-weight: 600;
        }
    } @else if ($type == "error") {
        background-color: scale-color(color(alert, Error), $lightness: 90%);
        border: 1px solid scale-color(color(alert, Error), $lightness: 80%);
        color: color(alert, Error);
        a {
            color: color(alert, Error);
            text-decoration: underline;
        }
    } @else if ($type == "success") {
        background-color: #dff0d8;
        border-color: #d0e9c6;
        color: #3c763d;
        a {
            color: #3c763d;
            text-decoration: underline;
        }
    }
}

@mixin loading {
    background: rgba(179, 179, 179, .66);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 7;
    text-align: center;
    ._spinner {
        background-image: url("data:image/svg+xml,%0A%3C!-- By Sam Herbert (@sherb), for everyone. More @ http://goo.gl/7AJzbL --%3E%3Csvg width='38' height='38' viewBox='0 0 38 38' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3ClinearGradient x1='8.042%25' y1='0%25' x2='65.682%25' y2='23.865%25' id='a'%3E%3Cstop stop-color='#{color(base, Secondary)}' stop-opacity='0' offset='0%25'/%3E%3Cstop stop-color='#{color(base, Secondary)}' stop-opacity='.631' offset='63.146%25'/%3E%3Cstop stop-color='#{color(base, Secondary)}' offset='100%25'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(1 1)'%3E%3Cpath d='M36 18c0-9.94-8.06-18-18-18' id='Oval-2' stroke='url(%23a)' stroke-width='2'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='0.9s' repeatCount='indefinite' /%3E%3C/path%3E%3Ccircle fill='#{color(base, Secondary)}' cx='36' cy='18' r='1'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='0.9s' repeatCount='indefinite' /%3E%3C/circle%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
        width: 4rem;
        height: 4rem;
        margin: 10% auto;
        display: block;
        background-size: contain;
        mask-size: contain;
        justify-content: center;
        z-index: 8;
    }
}

@mixin hover() {
    @include breakpoint(medium) {
        &:hover {
            @content;
        }
    }
}

@mixin default-button {
    @include transition;
    display: inline-block;
    background-color: color(base, Primary);
    border-bottom: .2rem solid darken(color(base, Primary), 20%);
    color: color(base, Secondary);
    font-family: $secondary-font;
    font-size: 1.3rem;
    height: 4rem;
    line-height: 2.8rem;
    text-transform: uppercase;
    padding: .5rem 1.5rem;
    text-align: center;
    cursor: pointer;
    @include breakpoint(medium) {
        padding: .5rem 1rem;
        font-size: 1.1rem;
        &:hover {
            background-color: color(base, PrimaryHover);
        }
    }
    @include breakpoint(large) {
        font-size: 1.4rem;
    }
    &:disabled {
        background: color(gray, Gray-100);
        cursor: not-allowed;
    }
    &._arrow {
        position: relative;
        margin: 1.5rem 0 0;
        &::after {
            content: '';
            display: block;
            background-image: url("data:image/svg+xml,%0A%3Csvg width='64px' height='19px' viewBox='0 0 64 19' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group-5' transform='translate(0.000000, 1.000000)' stroke='%23EFDD2D' stroke-width='1.801'%3E%3Cline x1='0' y1='8.50019547' x2='62' y2='8.50019547' id='Stroke-1'%3E%3C/line%3E%3Cpolyline id='Stroke-3' points='55.1501612 0 62 8.61337243 55.1501612 17'%3E%3C/polyline%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
            width: 6.2rem;
            height: 1.7rem;
            position: absolute;
            background-repeat: no-repeat;
            background-size: contain;
            left: 100%;
            top: 50%;
            transform: translateY(-50%);
            margin-left: 1rem;
        }
    }
    &._secondary {
        background-color: transparent;
        border: .2rem solid color(base, Secondary);
        line-height: 2.6rem;
        @include hover() {
            border-color: color(base, SecondaryHover);
            color: color(base, SecondaryHover);
        }
    }
    &._white {
        background-color: transparent;
        border: .1rem solid color(base, White);
        color: color(base, White);
        @include hover() {
            background: color(base, SecondaryHover);
        }
    }
    &._small {
        font-size: 1.2rem;
        height: 3rem;
        line-height: 3rem;
        padding: 0 2rem;
    }
}

@mixin autocomplete {
    margin: 0 0 2rem;
    .resolved__address {
        span {
            display: block;
            font-weight: normal;
            font-size: 1.3rem;
            &._error {
                color: color(alert, Error);
            }
        }
        label {
            font-size: 1.3rem;
            cursor: pointer;
            text-decoration: underline;
        }
        address {
            background: color(gray, Gray-100);
            display: inline-flex;
            padding: 1.5rem;
            min-width: 40%;
            font-size: 1.4rem;
            @include breakpoint(small only) {
                width: 100%;
            }
        }
    }
}

._button {
    @include default-button;
}

._loading {
    @include loading;
}
