.home .home__block._alternative {
    ._content {
        .--swiper-pagination {
            justify-content: flex-end;
            margin: 0 0 2rem;
        }
        .swiper-wrapper {
            margin: 2rem 1rem 0;
            @include breakpoint(medium) {
                margin: 0 1rem;
            }
            .swiper-slide {
                width: 65%;
                a {
                    padding: 0 1rem;
                    display: block;
                    .image__holder {
                        display: block;
                        img {
                            aspect-ratio: 24/29;
                            width: 100%;
                            max-width: 34rem;
                            object-fit: scale-down;
                            margin: 0 auto;
                            @include breakpoint(small only) {
                                aspect-ratio: 1/1;
                            }
                        }
                    }
                    h2 {
                        font-size: 1.8rem;
                        margin: 1rem 0 0;
                        padding: 0;
                        @include breakpoint(small only) {
                            font-size: 1.6rem;
                        }
                    }
                }
            }
        }
    }
}
