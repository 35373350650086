.product__compare-container {
    .product__compare-overlay {
        position: fixed;
        z-index: 80000;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, .35);
    }
    .product__compare-block {
        position: fixed;
        z-index: 80001;
        bottom: 0;
        right: 10rem;
        background: color(base, Secondary);
        box-shadow: 0 .2rem .4rem 0 rgba(0, 0, 0, .2);
        max-width: 34rem;
        @include breakpoint(small only) {
            left: 1rem;
            right: 1rem;
            max-width: 100%;
        }
        &._active {
            @include breakpoint(medium up) {
                width: 34rem;
            }
            .product__compare-header {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-chevron-down' width='20' height='20' viewBox='0 0 24 24' stroke-width='3' stroke='#{color(base, Primary)}' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z'/%3E%3Cpolyline points='6 9 12 15 18 9' /%3E%3C/svg%3E");
            }
        }
        .product__compare-header {
            text-align: center;
            background-color: color(base, Secondary);
            background-size: 1.6rem;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-chevron-up' width='20' height='20' viewBox='0 0 24 24' stroke-width='3' stroke='#{color(base, Primary)}' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z'/%3E%3Cpolyline points='6 15 12 9 18 15' /%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: right 1rem center;
            color: color(base, Primary);
            line-height: 3.4rem;
            font-size: 1.6rem;
            padding: 0 3.8rem 0 2rem;
            font-weight: 400;
            cursor: pointer;
            @include breakpoint(small only) {
                line-height: 4.6rem;
                font-size: 1.6rem;
            }
        }
        .product__compare-list {
            max-height: 33rem;
            overflow-y: auto;
            background: color(base, White);
            li {
                padding: .5rem;
                border-bottom: 1px solid color(gray, Gray-100);
                display: flex;
                align-items: center;
                gap: 1rem;
                &:last-child {
                    border: none;
                }
                .image__holder {
                    position: relative;
                    z-index: 1;
                    display: block;
                    background: color(gray, Gray-100);
                    flex: 0 0 6.3rem;
                    img {
                        aspect-ratio: 63/90;
                        width: 100%;
                        object-fit: scale-down;
                    }
                }
                .product__holder {
                    position: relative;
                    padding-right: 2.5rem;
                    a {
                        display: block;
                        font-size: 1.4rem;
                    }
                    .price__holder {
                        span {
                            font-size: 1.4rem;
                            line-height: 2.2rem;
                            font-weight: 900;
                            &.--old {
                                text-decoration: line-through;
                                color: color(gray, Gray-400);
                                font-weight: 400;
                            }
                        }
                    }
                }
                ._remove {
                    margin-left: auto;
                    cursor: pointer;
                    width: 1.6rem;
                    height: 1.6rem;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-x' width='20' height='20' viewBox='0 0 24 24' stroke-width='3' stroke='#{color(gray, Gray-200)}' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z'/%3E%3Cline x1='18' y1='6' x2='6' y2='18' /%3E%3Cline x1='6' y1='6' x2='18' y2='18' /%3E%3C/svg%3E");
                    background-size: contain;
                    margin-right: 1rem;
                    @include hover() {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-x' width='20' height='20' viewBox='0 0 24 24' stroke-width='3' stroke='#{color(gray, Gray-300)}' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z'/%3E%3Cline x1='18' y1='6' x2='6' y2='18' /%3E%3Cline x1='6' y1='6' x2='18' y2='18' /%3E%3C/svg%3E");
                    }
                }
            }
        }
        .product__compare-button {
            padding: .5rem;
            border-top: .1rem solid color(gray, Gray-100);
            background: color(base, White);
            a {
                width: 100%;
            }
        }
    }
}

.product__compare {
    display: flex;
    justify-content: center;
    margin: 1rem 0 0;
    @include breakpoint(large) {
        margin: 2rem 0 0;
    }
    label {
        display: flex;
        align-items: center;
        cursor: pointer;
        gap: .5rem;
        font-size: 1.4rem;
        &::before {
            content: '';
            border-radius: .2rem;
            display: block;
            width: 1.6rem;
            height: 1.6rem;
            border: .1rem solid color(base, Secondary);
            background-color: color(base, White);
            background-size: 1rem;
            background-repeat: no-repeat;
            background-position: center;
        }
        @include hover() {
            &::before {
                border-color: color(base, SecondaryHover);
                background-image: url("data:image/svg+xml,%0A%3Csvg width='16px' height='14px' viewBox='0 0 16 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline id='Stroke-1' stroke='#{color(gray, Gray-200)}' stroke-width='2' points='1 6.9031134 5.25802431 11.1611377 15 2'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
            }
        }
    }
    input:checked + label::before {
        background-image: url("data:image/svg+xml,%0A%3Csvg width='16px' height='14px' viewBox='0 0 16 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline id='Stroke-1' stroke='%23110047' stroke-width='3' points='1 6.9031134 5.25802431 11.1611377 15 2'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center;
        border-color: color(base, Secondary);
        background-size: 85%;
    }
}
