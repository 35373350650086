.slider__control {
    @media screen and (max-width: 1388px) {
        display: none;
    }
    @include transition;
    display: block;
    width: 2rem;
    height: 4rem;
    position: absolute;
    top: 30%;
    z-index: 8;
    cursor: pointer;
    background-size: cover;
    background-repeat: no-repeat;
    &._prev {
        left: -4rem;
        background-image: url("data:image/svg+xml,%0A%3Csvg width='20px' height='40px' viewBox='0 0 20 40' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round'%3E%3Cpolyline id='Stroke-1' stroke='%230A233D' stroke-width='2.748' points='18 38 2 19.7557264 18 2'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
        &:hover {
            background-image: url("data:image/svg+xml,%0A%3Csvg width='20px' height='40px' viewBox='0 0 20 40' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round'%3E%3Cpolyline id='Stroke-1' stroke='#{color(base, SecondaryHover)}' stroke-width='2.748' points='18 38 2 19.7557264 18 2'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
        }
    }
    &._next {
        right: -4rem;
        background-image: url("data:image/svg+xml,%0A%3Csvg width='20px' height='40px' viewBox='0 0 20 40' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round'%3E%3Cpolyline id='Stroke-1' stroke='%230A233D' stroke-width='2.748' transform='translate(10.000000, 20.000000) scale(-1, 1) translate(-10.000000, -20.000000) ' points='18 38 2 19.7557264 18 2'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
        &:hover {
            background-image: url("data:image/svg+xml,%0A%3Csvg width='20px' height='40px' viewBox='0 0 20 40' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round'%3E%3Cpolyline id='Stroke-1' stroke='#{color(base, SecondaryHover)}' stroke-width='2.748' transform='translate(10.000000, 20.000000) scale(-1, 1) translate(-10.000000, -20.000000) ' points='18 38 2 19.7557264 18 2'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
        }
    }
}

.swiper-container-horizontal > .swiper-pagination-progressbar {
    width: auto;
    position: relative;
    height: .2rem;
    background: color(gray, Gray-200);
    margin: 3rem 1rem 0;
    .swiper-pagination-progressbar-fill {
        background: color(base, Secondary);
        height: 2rem;
        top: -.2rem;
    }
}

.--swiper-pagination {
    display: flex;
    gap: .5rem;
    button {
        display: block;
        width: 2.6rem;
        height: 2.6rem;
        background-repeat: no-repeat;
        background-position: right center;
        background-image: url("data:image/svg+xml,%0A%3Csvg width='9px' height='14px' viewBox='0 0 9 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline id='Stroke-1' stroke='#{color(base, Secondary)}' stroke-width='2' points='1.005 1 6.891 6.886 1 12.776'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
        background-size: 1.2rem;
        cursor: pointer;
        &.swiper-button-disabled {
            background-image: url("data:image/svg+xml,%0A%3Csvg width='9px' height='14px' viewBox='0 0 9 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline id='Stroke-1' stroke='#{color(gray, Gray-200)}' stroke-width='2' points='1.005 1 6.891 6.886 1 12.776'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
        }
        @include hover() {
            &:not(.swiper-button-disabled) {
                background-image: url("data:image/svg+xml,%0A%3Csvg width='9px' height='14px' viewBox='0 0 9 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline id='Stroke-1' stroke='#{color(base, SecondaryHover)}' stroke-width='2' points='1.005 1 6.891 6.886 1 12.776'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
            }
        }
        &.--prev {
            transform: rotate(180deg);
            background-position: left center;
        }
    }
}
