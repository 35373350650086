/* Override default */
$grid__max-width: 130rem;
$grid__gutter: 2rem;
$grid__max-padding: 2rem;

$grid__breakpoints: (
    '': 0,
    small: 576,
    medium: 768,
    between-medium-small: 941,
    large: 1025,
    xlarge: 1200,
    xxlarge: 1440
) !default;

$color-palette: (
    base: (
        White: #FFF,
        Black: #000,
        DefaultFontColor: #000422,
        inputColor: #000422,
        Primary: #EFDD2D,
        PrimaryHover: darken(rgb(239, 221, 45), 10%),
        PrimaryLight: lighten(rgb(239, 221, 45, .75), 20%),
        Secondary: #000422,
        SecondaryHover: #000A58,
        SecondaryBorder: rgba(0, 4, 34, .2)
    ),
    gray: (
        Gray-100: #F4F4F4,
        Gray-200: #dbdbdb,
        Gray-300: #bdbdbd,
        Gray-400: #9e9fa9
    ),
    alert: (
        Error: #D8000C,
        Warning: #9F6000,
        Success: #270,
        Neutral: #059
    )
);

$default-font: 'Laslo', sans-serif;
$secondary-font: 'Laslo Wide', sans-serif;
