.catalog-category-landing-page, .page-builder__wrapper {
    .breadcrumbs {
        display: none;
    }
    .landing__header {
        @include breakpoint(medium) {
            margin: 4rem 0 0;
        }
        .top__content {
            @include breakpoint(small only) {
                margin: 3rem 0 0;
            }
            @include breakpoint(medium) {
                padding: .8rem 0;
            }
            text-align: center;
            h1 {
                font-size: 2.4rem;
                text-transform: uppercase;
                margin: 0 0 1rem;
            }
            p {
                margin: 0 auto 1.5rem;
                max-width: 60%;
                font-size: 1.6rem;
                line-height: normal;
            }
        }
        .static__filter-blocks {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            height: 100%;
            a {
                display: inline-block;
            }
            img {
                aspect-ratio: 103/69;
                object-fit: cover;
            }
        }
    }
    .category__usp._slider {
        padding: 5rem 0;
        position: relative;
        p {
            margin: 0 auto;
            max-width: 60%;
        }
        .slider__control {
            display: block;
            top: 50%;
            transform: translateY(-50%);
            width: 15px;
            height: 30px;
            &._prev {
                left: 1.5rem;
            }
            &._next {
                right: 1.5rem
            }
        }
        .swiper-pagination {
            left: 0;
            right: 0;
            bottom: 1.5rem;
            .swiper-pagination-bullet {
                opacity: 1;
                width: .8rem;
                height: .8rem;
                margin: 0 .25rem;
                background: transparent;
                border: 1px solid color(gray, Gray-300);
                &.swiper-pagination-bullet-active {
                    background: color(gray, Gray-300);
                }
            }
        }
    }
    .landing__series {
        @include block-spacing;
        @include breakpoint(small only) {
            .grid-container {
                padding: 0;
            }
        }
        @include breakpoint(medium) {
            &._left {
                .grid-x {
                    > div {
                        &:nth-child(3) {
                            order: 0;
                        }
                        &:nth-child(2) {
                            order: 1
                        }
                    }
                }
            }
        }
        ._header {
            text-align: center;
            margin: 0 0 3rem;
            h2 {
                text-transform: uppercase;
            }
            p {
                text-transform: uppercase;
            }
        }
        .series__intro {
            display: flex;
            margin: 0 0 3rem;
            padding: 0 3rem;
            @include breakpoint(small only) {
                padding: 1rem;
            }
            > img, > div {
                flex: 1;
                width: 50%;
            }
            > img {
                aspect-ratio: 24 / 29;
                object-fit: cover;
            }
            > div {
                text-align: center;
                img {
                    margin: 0 auto 1rem;
                    aspect-ratio: 15 / 5;
                    object-fit: cover;
                }
            }
        }
        ._content {
            padding: 3rem;
            background: color(base, Primary);
            @include breakpoint(small only) {
                border-bottom: .3rem solid color(base, Secondary);
            }
            p {
                margin: 0 0 1.5rem;
            }
            h3 {
                font-family: $default-font;
                font-weight: 900;
                font-size: 1.4rem;
                line-height: 2.1rem;
                text-transform: uppercase;
                margin: 0 0 1rem;
            }
            ul {
                display: flex;
                margin: 0 -.5rem;
                li {
                    padding: 0 .5rem;
                    margin: 0 0 .5rem;
                    a {
                        display: block;
                        border: .1rem solid color(base, Secondary);
                        border-radius: .4rem;
                        padding: 0 .5rem;
                        @include breakpoint(medium) {
                            @include transition;
                            &:hover {
                                background-color: color(base, Secondary);
                                color: color(base, Primary);
                            }
                        }
                    }
                }
            }
        }
    }
    .landing__filter {
        @include block-spacing;
        background: color(gray, Gray-100);
        padding: 3rem 0;
        h2 {
            max-width: 60%;
            font-size: 2rem;
            line-height: normal;
            margin: 0 0 3rem;
            text-transform: uppercase;
        }
        .grid-container {
            @include breakpoint(small only) {
                padding: 0 0 0 2rem;
            }
            .swiper-container {
                padding-bottom: .5rem;
                .swiper-slide {
                    width: 75%;
                    a {
                        display: block;
                        img {
                            width: 100%;
                        }
                    }
                }
            }
        }
        @include breakpoint(medium) {
            .filter__slider {
                display: flex;
                justify-content: space-between;
                h2 {
                    display: flex;
                    align-items: center;
                    font-size: 2.6rem;
                    max-width: 20%;
                }
            }
        }
    }
    .category__usp {
        @include block-spacing;
    }
}
