.breadcrumbs {
    overflow: hidden;
    padding: 3rem 0;
    ol {
        display: flex;
        align-items: center;
        li {
            display: flex;
            align-items: center;
            a, span {
                display: block;
                font-weight: normal;
                text-transform: uppercase;
                font-size: 1.2rem;
                white-space: nowrap;
                font-weight: 100;
            }
            a:hover {
                text-decoration: underline;
            }
            ._sep {
                display: block;
                margin: 0 .5rem;
            }
        }
    }
}
