.checkout-cart_body {
    @include breakpoint(small only) {
        header {
            section {
                display: none;
            }
            .--logo {
                padding-right: 3.5rem;
            }
        }
    }
    footer {
        margin: 0;
        .newsletter__subscribe, .footer-container {
            display: none;
        }
    }
    .checkout-cart {
        > .grid-container .grid-x {
            min-height: calc(100vh - 17.7rem);
        }
        .breadcrumbs {
            display: none;
        }
        ._header {
            padding: 3rem 0;
            @include breakpoint(small only) {
                padding: 2rem 0;
            }
            ._holder {
                display: flex;
                align-items: center;
                h1 {
                    @include head-font;
                    font-size: 2.2rem;
                    text-transform: uppercase;
                    line-height: normal;
                    display: flex;
                    align-items: center;
                    @include breakpoint(small only) {
                        font-size: 2rem;
                    }
                    span {
                        font-size: 2.2rem;
                        font-weight: normal;
                        margin-left: .5rem;
                        @include breakpoint(small only) {
                            font-size: 1.6rem;
                        }
                    }
                }
            }
            .messages, .cart__message {
                margin: 1.5rem 0 0;
            }
        }
        @media screen and (min-width: 768px) and (max-width: 1140px) {
            .breadcrumbs + span {
                > .grid-x.grid-padding-x {
                    .medium-13 {
                        width: 62.5%;
                    }
                    .medium-8 {
                        width: 37.5%;
                        margin-left: 0;
                    }
                }
            }
        }
        .cart__items {
            .cart-container {
                select {
                    @include breakpoint(small only) {
                        line-height: 3rem;
                        background-position: right 2rem top 1rem;
                    }
                    option {
                        @include breakpoint(small only) {
                            text-align: center;
                        }
                    }
                }
                .cart-item-list-loader {
                    font-size: 1.4rem;
                    font-weight: normal;
                }
                .cart__item {
                    display: flex;
                    border-bottom: 2px solid color(gray, Gray-200);
                    padding: 3rem 0;
                    @include breakpoint(small only) {
                        flex-wrap: wrap;
                        justify-content: space-between;
                        &:last-child {
                            border: none;
                        }
                    }
                    > a {
                        display: flex;
                        background: color(gray, Gray-100);
                        img {
                            aspect-ratio: 24/29;
                            margin: 0 auto;
                            align-self: center;
                            object-fit: scale-down;
                        }
                    }
                    ._information {
                        display: flex;
                        flex-flow: column;
                        @include rating;
                        flex: 1;
                        padding: 0 2rem 2rem;
                        > a {
                            @include head-font;
                            font-size: 1.8rem;
                            line-height: 2.4rem;
                            text-transform: uppercase;
                            @include breakpoint(small only) {
                                font-size: 1.2rem;
                                line-height: 1.6rem;
                            }
                        }
                        ._rating {
                            margin: .5rem 0 0;
                        }
                        .product-usp, .available-from {
                            margin: 1rem 0 0;
                        }
                        ul {
                            margin: 3rem 0 1rem;
                            @include breakpoint(small only) {
                                margin: 1rem 0;
                            }
                            li {
                                font-size: 1.4rem;
                                line-height: 2rem;
                                font-weight: normal;
                                color: color(gray, Gray-300);
                            }
                        }
                        .row__price {
                            position: relative;
                            margin-top: auto;
                            margin-right: auto;
                            ._loading {
                                position: static;
                                ._spinner {
                                    width: 2.5rem;
                                    height: 2.5rem;
                                    margin: 0;
                                }
                            }
                            span {
                                font-weight: normal;
                                font-size: 2rem;
                            }
                        }
                    }
                    ._actions {
                        margin-left: auto;
                        display: flex;
                        flex-flow: column;
                        padding: 0 0 2rem;
                        @include breakpoint(small only) {
                            flex-basis: 100%;
                            flex-flow: row-reverse;
                            align-items: center;
                            padding: 2rem 0 0;
                        }
                        ._remove {
                            cursor: pointer;
                            width: 2.4rem;
                            height: 2.4rem;
                            margin-left: auto;
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5' viewBox='0 0 20 20' fill='#{color(gray, Gray-200)}'%3E%3Cpath fill-rule='evenodd' d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z' clip-rule='evenodd' /%3E%3C/svg%3E");
                            @include breakpoint(medium) {
                                @include transition;
                                &:hover {
                                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5' viewBox='0 0 20 20' fill='#{color(gray, Gray-300)}'%3E%3Cpath fill-rule='evenodd' d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z' clip-rule='evenodd' /%3E%3C/svg%3E");
                                }
                            }
                        }
                        select {
                            margin-top: auto;
                            width: 7.5rem;
                        }
                    }
                }
            }
        }
        .cart__crosssell {
            h4 {
                text-transform: uppercase;
                margin: 3rem 0;
                text-align: center;
                font-size: 2rem;
            }
            .grid-container {
                padding: 0;
                margin: 0 -1rem;
                .swiper-wrapper {
                    .swiper-slide {
                        width: 33.33333344%;
                        @include breakpoint(small only) {
                            width: 80%;
                        }
                        li {
                            padding: 0 1rem;
                            > a .product__holder h3 {
                                font-size: 1.2rem;
                                margin: 0 0 3rem;
                            }
                            .add-to-cart__container button {
                                font-size: 1.2rem;
                            }
                        }
                    }
                }
            }
        }
        .cart-empty {
            p {
                font-size: 1.6rem;
                a {
                    font-size: 1.6rem;
                    text-decoration: underline;
                }
            }
        }
        .cart__side-bar-container {
            @include breakpoint(small only) {
                margin-top: 2rem;
                &.cell {
                    padding: 0;
                }
            }
            .cart__side-bar {
                display: flex;
                flex-flow: column;
                height: 100%;
                @include breakpoint(medium) {
                    background: color(gray, Gray-100);
                    padding: 8rem 0 2rem;
                }
                > h3 {
                    text-align: center;
                    margin: 0 0 2rem;
                    text-transform: uppercase;
                }
                ._usp {
                    padding: 3rem;
                    border-top: 2px solid color(gray, Gray-200);
                    @include breakpoint(small only) {
                        order: 2;
                    }
                    ul {
                        li {
                            background-image: url("data:image/svg+xml,%0A%3Csvg width='21px' height='16px' viewBox='0 0 21 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group-23' transform='translate(-68.000000, -214.000000)' stroke='#{color(alert, Success)}' stroke-width='2.388'%3E%3Cpolyline id='Stroke-5' points='69.0141 220.7336 75.7591 227.4786 88.1151 215.1236'%3E%3C/polyline%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                            background-repeat: no-repeat;
                            background-position: left top 1rem;
                            font-size: 1.7rem;
                            line-height: 2.8rem;
                            padding-left: 2.8rem;
                            background-size: 1.5rem;
                            a {
                                font-size: 1.7rem;
                            }
                        }
                    }
                }
                .cart__coupon {
                    padding: 3rem;
                    border-top: 2px solid color(gray, Gray-200);
                    @include breakpoint(small only) {
                        order: 1;
                    }
                    .coupon-code__container {
                        position: relative;
                        ._loading {
                            background: transparent;
                            position: static;
                            ._spinner {
                                margin: 0 auto;
                                width: 3rem;
                                height: 3rem;
                            }
                        }
                        .toggle-coupon {
                            display: flex;
                            align-items: center;
                            label {
                                font-size: 1.3rem;
                                margin-right: 1rem;
                                line-height: 2rem;
                                font-weight: 100;
                            }
                        }
                        .form-holder {
                            display: flex;
                            margin: 2rem 0 0;
                            input {
                                height: 4rem;
                            }
                            button {
                                font-family: $default-font;
                            }
                        }
                    }
                }
                .totals-container {
                    padding: 3rem;
                    border-top: 2px solid color(gray, Gray-200);
                    @include breakpoint(small only) {
                        background: color(gray, Gray-100);
                        order: 3;
                    }
                    .totals-container__totals {
                        position: relative;
                        min-height: 10rem;
                        ._loading {
                            background-color: transparent;
                        }
                        ul {
                            li {
                                display: flex;
                                span {
                                    display: block;
                                    flex: 1;
                                    font-size: 1.4rem;
                                    line-height: 2.6rem;
                                    &._free {
                                        color: color(alert, Success);
                                    }
                                    &:last-child {
                                        text-align: right;
                                    }
                                }
                                &._grand {
                                    border-top: 1px solid color(gray, Gray-200);
                                    margin: .8rem 0 0;
                                    padding: .8rem 0 0;
                                    span {
                                        font-weight: normal;
                                        font-size: 1.8rem;
                                    }
                                }
                                small {
                                    font-size: 1.2rem;
                                }
                            }
                        }
                    }
                }
                .summary {
                    padding: 30px 0;
                    h3 {
                        font-size: 1.8rem;
                        margin: 0 0 1rem;
                        &:last-of-type {
                            margin: 1rem 0;
                        }
                    }
                    ul {
                        margin: 1rem 0;
                        list-style: disc outside;
                        padding-left: 1.5rem;
                    }
                }
                ._actions {
                    padding: 3rem;
                    border-top: 2px solid color(gray, Gray-200);
                    @include breakpoint(small only) {
                        background: color(gray, Gray-100);
                        order: 4;
                    }
                    button {
                        @include default-button;
                    }
                }
                @include breakpoint(small only) {
                    .cart__crosssell {
                        order: 5;
                        padding: 0 2rem;
                    }
                }
                .side-bar__bottom {
                    @include breakpoint(small only) {
                        background: color(base, White);
                        order: 6;
                    }
                    display: flex;
                    flex-flow: column;
                    padding: 3rem;
                    border-top: 2px solid color(gray, Gray-200);
                    justify-content: center;
                }
            }
        }
    }
}

p.--secure {
    margin: 1rem 0 0;
    font-weight: normal;
    font-size: 1.4rem;
    color: color(alert, Success);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='#{color(alert, Success)}'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z' /%3E%3C/svg%3E");
    background-size: 1.5rem;
    background-repeat: no-repeat;
    padding-left: 2rem;
    background-position: left center;
}
