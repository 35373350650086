.catalog-compare {
    @include breakpoint(small only) {
        .breadcrumbs {
            display: none;
        }
    }
    .--header {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
        h1 {
            font-size: 2.2rem;
            line-height: 2.8rem;
            text-transform: uppercase;
        }
        .--swiper-pagination {
            margin: 0 0 0 auto;
        }
    }
    .compare__products {
        .compare__products-product {
            position: relative;
            .--remove {
                width: 2rem;
                height: 2rem;
                position: absolute;
                z-index: 2;
                top: 1rem;
                right: 1rem;
                margin-left: auto;
                cursor: pointer;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-x' width='20' height='20' viewBox='0 0 24 24' stroke-width='3' stroke='#{color(gray, Gray-200)}' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z'/%3E%3Cline x1='18' y1='6' x2='6' y2='18' /%3E%3Cline x1='6' y1='6' x2='18' y2='18' /%3E%3C/svg%3E");
                background-size: contain;
                @include hover() {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-x' width='20' height='20' viewBox='0 0 24 24' stroke-width='3' stroke='#{color(gray, Gray-300)}' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z'/%3E%3Cline x1='18' y1='6' x2='6' y2='18' /%3E%3Cline x1='6' y1='6' x2='18' y2='18' /%3E%3C/svg%3E");
                }
            }
            .product__header {
                margin: 0 0 1.5rem;
                padding: 0 .5rem;
                position: relative;
            }
            .catalog__category-list ul li > a {
                padding: 1rem;
                .toggle__wishlist {
                    display: none;
                }
                .image__holder .--message {
                    display: none;
                }
                .product__holder {
                    h3 {
                        font-size: 1.3rem;
                    }
                    .product__swatches {
                        display: none;
                    }
                    .price {
                        font-size: 1.4rem;
                    }
                    .add__to-cart .add-to-cart__container {
                        a, button {
                            font-size: 1rem;
                            line-height: 3.2rem;
                            height: 3.2rem;
                            padding: 0 2rem;
                        }
                    }
                }
            }
            ul._attributes {
                li {
                    border-left: .1rem solid color(gray, Gray-100);
                    padding: 0 1rem;
                    font-size: 1.4rem;
                    line-height: 2rem;
                    min-height: 5rem;
                    &:nth-child(odd) {
                        background: color(gray, Gray-100);
                    }
                    > div {
                        padding: 1.5rem 0;
                        word-break: break-word;
                    }
                }
            }
            &._attributes {
                ul {
                    li {
                        border-left: none;
                        font-weight: 600;
                    }
                }
            }
        }
    }
}
