.catalog__category-list {
    position: relative;

    ._loading {
        background: rgba(255, 255, 255, .7);
    }

    ul li {
        display: flex;
        flex-direction: column;
        > a {
            display: flex;
            flex-direction: column;
            flex: 1;
            background: color(gray, Gray-100);
            padding: 1rem;
            border: .1rem solid transparent;
            margin: 0 0 2rem;
            position: relative;
            height: calc(100% - 2rem);

            @include hover() {
                border-color: color(gray, Gray-200);
            }
            @include breakpoint(large) {
                padding: 2rem;
            }

            .toggle__wishlist {
                display: block;
                position: absolute;
                top: 1rem;
                right: 1rem;
                z-index: 2;
                @include breakpoint(medium) {
                    top: 2rem;
                    right: 2rem;
                }

                button {
                    width: 2rem;
                    height: 2rem;
                    cursor: pointer;
                    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg width='23px' height='21px' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;'%3E%3Cg transform='matrix(1,0,0,1,1.46284,0.273239)'%3E%3Cg transform='matrix(0.952116,0,0,0.952116,0.5,0.485773)'%3E%3Cpath d='M14.667,1.264C13.255,1.264 11.927,1.814 10.928,2.813L9.978,3.763L9.028,2.813C8.029,1.814 6.701,1.264 5.288,1.264C3.876,1.264 2.548,1.814 1.549,2.813C0.55,3.812 0,5.14 0,6.553C0,7.965 0.55,9.293 1.549,10.292L9.165,17.908L9.978,18.721L18.407,10.292C19.405,9.293 19.956,7.965 19.956,6.553C19.956,5.14 19.405,3.812 18.407,2.813C17.407,1.814 16.079,1.264 14.667,1.264M14.667,2.414C15.773,2.414 16.812,2.844 17.594,3.626C18.376,4.408 18.806,5.447 18.806,6.553C18.806,7.658 18.376,8.698 17.594,9.479L9.978,17.096L2.362,9.479C1.58,8.698 1.149,7.658 1.149,6.553C1.149,5.447 1.58,4.408 2.362,3.626C3.144,2.844 4.183,2.414 5.288,2.414C6.394,2.414 7.433,2.844 8.215,3.626L9.978,5.389L11.74,3.626C12.522,2.844 13.562,2.414 14.667,2.414' style='fill:rgb(189,189,189);fill-rule:nonzero;stroke:rgb(189,189,189);stroke-width:1px;'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                    @include hover() {
                        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg width='23px' height='21px' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;'%3E%3Cg transform='matrix(1,0,0,1,1.46284,0.273239)'%3E%3Cg transform='matrix(0.952116,0,0,0.952116,0.5,0.485773)'%3E%3Cpath d='M14.667,1.264C13.255,1.264 11.927,1.814 10.928,2.813L9.978,3.763L9.028,2.813C8.029,1.814 6.701,1.264 5.288,1.264C3.876,1.264 2.548,1.814 1.549,2.813C0.55,3.812 0,5.14 0,6.553C0,7.965 0.55,9.293 1.549,10.292L9.165,17.908L9.978,18.721L18.407,10.292C19.405,9.293 19.956,7.965 19.956,6.553C19.956,5.14 19.405,3.812 18.407,2.813C17.407,1.814 16.079,1.264 14.667,1.264M14.667,2.414C15.773,2.414 16.812,2.844 17.594,3.626C18.376,4.408 18.806,5.447 18.806,6.553C18.806,7.658 18.376,8.698 17.594,9.479L9.978,17.096L2.362,9.479C1.58,8.698 1.149,7.658 1.149,6.553C1.149,5.447 1.58,4.408 2.362,3.626C3.144,2.844 4.183,2.414 5.288,2.414C6.394,2.414 7.433,2.844 8.215,3.626L9.978,5.389L11.74,3.626C12.522,2.844 13.562,2.414 14.667,2.414' style='fill:rgb(158,159,169);fill-rule:nonzero;stroke:rgb(158,159,169);stroke-width:1px;'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");                    }
                }

                &.--active {
                    button {
                        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg width='23px' height='21px' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;'%3E%3Cg transform='matrix(1,0,0,1,1.46284,0.273239)'%3E%3Cg%3E%3Cg transform='matrix(0.952116,0,0,0.952116,0.5,0.485773)'%3E%3Cpath d='M14.667,1.264C13.255,1.264 11.927,1.814 10.928,2.813L9.978,3.763L9.028,2.813C8.029,1.814 6.701,1.264 5.288,1.264C3.876,1.264 2.548,1.814 1.549,2.813C0.55,3.812 0,5.14 0,6.553C0,7.965 0.55,9.293 1.549,10.292L9.165,17.908L9.978,18.721L18.407,10.292C19.405,9.293 19.956,7.965 19.956,6.553C19.956,5.14 19.405,3.812 18.407,2.813C17.407,1.814 16.079,1.264 14.667,1.264M14.667,2.414C15.773,2.414 16.812,2.844 17.594,3.626C18.376,4.408 18.806,5.447 18.806,6.553C18.806,7.658 18.376,8.698 17.594,9.479L9.978,17.096L2.362,9.479C1.58,8.698 1.149,7.658 1.149,6.553C1.149,5.447 1.58,4.408 2.362,3.626C3.144,2.844 4.183,2.414 5.288,2.414C6.394,2.414 7.433,2.844 8.215,3.626L9.978,5.389L11.74,3.626C12.522,2.844 13.562,2.414 14.667,2.414' style='fill:rgb(0,10,88);fill-rule:nonzero;stroke:rgb(0,10,88);stroke-width:1px;'/%3E%3C/g%3E%3Cpath d='M14.465,2.784C15.518,2.784 16.507,3.194 17.251,3.938C17.996,4.683 18.406,5.672 18.406,6.725C18.406,7.777 17.996,8.767 17.251,9.511L10,16.763L2.749,9.511C2.004,8.767 1.594,7.777 1.594,6.725C1.594,5.672 2.004,4.682 2.749,3.938C3.493,3.194 4.483,2.784 5.535,2.784C6.588,2.784 7.577,3.194 8.322,3.938L10,5.617L11.678,3.938C12.423,3.194 13.412,2.784 14.465,2.784Z' style='fill:rgb(239,221,45);'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");                        background-size: contain;
                    }
                }

                &.--loading-wishlist {
                    button {
                        cursor: not-allowed;
                        background-image: url("data:image/svg+xml,%0A%3C!-- By Sam Herbert (@sherb), for everyone. More @ http://goo.gl/7AJzbL --%3E%3Csvg width='38' height='38' viewBox='0 0 38 38' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3ClinearGradient x1='8.042%25' y1='0%25' x2='65.682%25' y2='23.865%25' id='a'%3E%3Cstop stop-color='#{color(base, Secondary)}' stop-opacity='0' offset='0%25'/%3E%3Cstop stop-color='#{color(base, Secondary)}' stop-opacity='.631' offset='63.146%25'/%3E%3Cstop stop-color='#{color(base, Secondary)}' offset='100%25'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(1 1)'%3E%3Cpath d='M36 18c0-9.94-8.06-18-18-18' id='Oval-2' stroke='url(%23a)' stroke-width='2'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='0.9s' repeatCount='indefinite' /%3E%3C/path%3E%3Ccircle fill='#{color(base, Secondary)}' cx='36' cy='18' r='1'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='0.9s' repeatCount='indefinite' /%3E%3C/circle%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
                    }
                }

                &.--remove {
                    button {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(gray, Gray-200)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z' /%3E%3C/svg%3E%0A");
                        background-size: 2.4rem;
                        @include hover() {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(gray, Gray-300)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z' /%3E%3C/svg%3E%0A");
                        }
                    }
                }
            }

            .image__holder {
                display: block;
                position: relative;
                @include breakpoint(medium) {
                    margin: 2rem 0 1rem;
                }
                .--message {
                    text-transform: uppercase;
                    display: inline-block;
                    background-color: #ca504d;
                    font-size: 1.2rem;
                    font-family: $default-font;
                    font-weight: 600;
                    color: white;
                    padding: .2rem 1rem;
                    border-radius: .3rem;
                    position: absolute;
                    top: -.5rem;
                    left: -.5rem;
                    @include breakpoint(medium) {
                        font-size: 1.3rem;
                        text-align: center;
                        position: absolute;
                        top: -2rem;
                        left: 0;
                    }
                }
                img {
                    aspect-ratio: 1/1;
                    width: 100%;
                    object-fit: scale-down;
                    @include breakpoint(medium) {
                        aspect-ratio: 1/1.1;
                    }
                }
            }

            .product__holder {
                display: flex;
                flex-direction: column;
                h3 {
                    text-align: center;
                    text-transform: none;
                    font-size: 1.3rem !important;
                    line-height: normal;
                    margin: 0 0 1rem;
                    @include breakpoint(medium) {
                        font-size: 1.4rem !important;
                    }
                    @include breakpoint(large) {
                        font-size: 1.6rem !important;
                    }
                }

                .product__swatches {
                    display: flex;
                    gap: .5rem;
                    justify-content: center;
                    margin: 1rem 0 1rem;
                    @include breakpoint(medium) {
                        margin: 1rem 0 1.5rem;
                    }

                    > a {
                        display: block;
                        padding: .1rem;
                        border: .1rem solid color(gray, Gray-200);

                        > span {
                            display: block;
                            width: 5rem;
                            height: 5rem;
                        }

                        @include hover() {
                            border-color: color(base, Secondary);
                        }
                        &.--more {
                            position: relative;
                            img {
                                opacity: 0.5;
                            }
                            .--amount {
                                position: absolute;
                                left: 50%;
                                top: 50%;
                                transform: translate(-50%, -50%);
                                font-weight: 600;
                                font-size: 3rem;
                                text-align: center;
                                line-height: 5rem;
                            }
                        }
                    }
                }

                .price__holder {
                    display: flex;
                    gap: 1rem;
                    align-items: center;
                    justify-content: center;
                    padding: 1rem 0 0;
                    margin-top: auto;
                    @include breakpoint(medium) {
                        gap: 1.5rem;
                    }
                    > span {
                        font-weight: 100;
                        font-size: 1.4rem;
                        font-family: 'Laslo Wide', sans-serif;
                        @include breakpoint(large) {
                            font-size: 1.8rem;
                        }

                        &.--old {
                            text-decoration: line-through;
                            color: color(gray, Gray-400);
                            font-size: 1.2rem;
                            @include breakpoint(large) {
                                font-size: 1.4rem;
                            }
                        }
                    }
                }

                .add__to-cart {
                    text-align: center;
                    display: block;
                    margin-top: 1rem;
                    @include breakpoint(medium) {
                        padding: 1rem 0 0;
                        margin-top: 1rem;
                    }
                    .add-to-cart__container {
                        display: flex;
                        justify-content: center;

                        button, a {
                            @include default-button;
                            line-height: 4rem !important;
                            font-size: 1rem;
                            padding: 0 .5rem;
                            width: 100%;
                            @include breakpoint(medium) {
                                font-size: 1.3rem;
                                padding: 0 1rem;
                            }
                            &.--loading-cart, &.--added-cart {
                                border-bottom: none;
                            }
                        }
                    }
                }
            }
        }

        .static__content {
            height: calc(100% - 2rem);
            background: color(base, Secondary);
            position: relative;

            p {
                height: 100%;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            h3 {
                color: color(base, Primary);
                position: absolute;
                left: 2rem;
                bottom: 5rem;
                right: 2rem;
                font-size: clamp(1.8rem, 2.4vw, 3.2rem);
                text-transform: uppercase;
            }
        }
    }

    .category__usp {
        margin: 0 0 2rem;

        .cell {
            text-align: center;

            img {
                margin: 0 auto 1rem;
            }

            @include breakpoint(small only) {
                &:last-of-type {
                    display: none;
                }
            }
        }
    }
}
