header {
    .sticky {
        background: color(base, Secondary);
        padding: 1.5rem 0 0;
        > .grid-container {
            display: flex;
            align-items: flex-end;
            .mobile__menu {
                flex: 0 0 6rem;
                justify-content: left;
                align-self: flex-end;
                display: none;
                @include breakpoint(medium) {
                    flex: 0 0 5rem;
                    margin-bottom: 1rem;
                }
                @media screen and (max-width: 1024px) {
                    display: flex;
                }
                .menu__toggle {
                    width: 2.2rem;
                    height: 1.7rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    margin-bottom: 1.5rem;
                    > span {
                        height: .3rem;
                        width: 100%;
                        background-color: color(base, Primary);
                        transition: all 100ms ease-in-out;
                        &.--close {
                            &:nth-of-type(1) {
                                transition: all 100ms ease-in-out;
                                transform: rotate(45deg);
                                transform-origin: top left;
                                width: 2.1rem;
                            }
                            &:nth-of-type(2) {
                                transition: all 100ms ease-in-out;
                                transform-origin: center;
                                width: 0;
                            }
                            &:nth-of-type(3) {
                                transition: all 100ms ease-in-out;
                                transform: rotate(-45deg);
                                transform-origin: bottom left;
                                width: 2.1rem;
                            }
                        }
                    }
                }
            }
            .--logo {
                @include transition;
                display: flex;
                flex: 1;
                justify-content: center;
                margin: 0 0 1.5rem;
                @include breakpoint(medium) {
                    flex: 0 0 11.2rem;
                }
                @include breakpoint(small only) {
                    max-height: 3.5rem;
                    img {
                        height: 3.5rem;
                    }
                }
            }
            .header__nav {
                flex: 1;
                margin: 0 4rem;
                top: .5rem;
                @media screen and (max-width: 1000px) {
                    display: none;
                }
                > ul {
                    display: flex;
                    align-items: center;
                    gap: clamp(.5rem, 4vw, 3rem);
                    @media screen and (max-width: 1265px) {
                        gap: 2rem;
                    }
                    > li {
                        > a, > span {
                            @include transition;
                            cursor: pointer;
                            display: block;
                            font-size: 2.1rem;
                            color: color(base, Primary);
                            position: relative;
                            font-weight: 400;
                            padding-bottom: 1.5rem;
                            @media screen and (max-width: 1265px) {
                                font-size: 1.3rem;
                                @media screen and (max-width: 1135px) {
                                    font-size: 1.2rem;
                                }
                            }
                            &::after {
                                @include transition;
                                content: '';
                                height: .2rem;
                                width: 0;
                                background: color(base, Primary);
                                position: absolute;
                                left: 0;
                                right: 0;
                                bottom: .5rem;
                            }
                        }
                        .--dropdown {
                            display: none;
                            border-top: .2rem solid color(base, Black);
                            background: color(base, Secondary);
                            position: absolute;
                            top: 100%;
                            left: 0;
                            right: 0;
                            padding: 4rem 0 7rem;
                            .grid-container {
                                display: grid;
                                grid-template-columns: repeat(5, 1fr);
                                grid-gap: 3rem 4rem;
                                ul {
                                    flex: 1;
                                    &:not(:nth-child(5n)) {
                                        border-right: .1rem solid color(base, Primary);
                                    }
                                    li {
                                        a {
                                            display: inline-block;
                                            font-size: 1.4rem;
                                            line-height: 2.2rem;
                                            font-weight: 300;
                                            color: color(base, Primary);
                                            padding: 0 1rem 0 0;
                                            @include breakpoint(medium) {
                                                &:hover {
                                                    text-decoration: underline;
                                                }
                                            }
                                        }
                                        &:first-child a {
                                            font-weight: 900;
                                            margin-bottom: 1.5rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &:hover {
                        > li {
                            > a, > span {
                                color: color(base, Primary);
                            }
                            &:hover {
                                > a, > span {
                                    color: color(base, Primary);
                                    &::after {
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .header--right {
                display: flex;
                align-items: center;
                gap: 1rem;
                margin-left: auto;
                margin-bottom: 1.5rem;
                @include breakpoint(medium) {
                    gap: 2rem;
                    @media screen and (max-width: 1265px) {
                        gap: 1.5rem;
                    }
                }
                > section {
                    width: auto;
                }
                > a, > section a, button {
                    display: block;
                    width: 2.5rem;
                    height: 2.5rem;
                    background-repeat: no-repeat;
                    background-position: bottom;
                    position: relative;
                    @include breakpoint(medium) {
                        background-position: center;
                    }
                    &._search {
                        cursor: pointer;
                        background-image: url("data:image/svg+xml,%0A%3Csvg width='20px' height='21px' viewBox='0 0 20 21' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round'%3E%3Cg id='Group-5' transform='translate(1.000000, 1.000000)' stroke='#{color(base, Primary)}' stroke-width='1.5'%3E%3Cpath d='M14.77,7.385 C14.77,11.464 11.464,14.77 7.385,14.77 C3.306,14.77 0,11.464 0,7.385 C0,3.306 3.306,0 7.385,0 C11.464,0 14.77,3.306 14.77,7.385 Z' id='Stroke-1'%3E%3C/path%3E%3Cline x1='17.8084' y1='19.2332' x2='11.9594' y2='13.3842' id='Stroke-3'%3E%3C/line%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                        @include hover() {
                            background-image: url("data:image/svg+xml,%0A%3Csvg width='20px' height='21px' viewBox='0 0 20 21' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round'%3E%3Cg id='Group-5' transform='translate(1.000000, 1.000000)' stroke='#{color(base, PrimaryHover)}' stroke-width='1.5'%3E%3Cpath d='M14.77,7.385 C14.77,11.464 11.464,14.77 7.385,14.77 C3.306,14.77 0,11.464 0,7.385 C0,3.306 3.306,0 7.385,0 C11.464,0 14.77,3.306 14.77,7.385 Z' id='Stroke-1'%3E%3C/path%3E%3Cline x1='17.8084' y1='19.2332' x2='11.9594' y2='13.3842' id='Stroke-3'%3E%3C/line%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                        }
                    }
                    &._customer {
                        background-image: url("data:image/svg+xml,%0A%3Csvg width='21px' height='22px' viewBox='0 0 21 22' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round'%3E%3Cg id='Group-5' transform='translate(1.000000, 1.000000)' stroke='#{color(base, Primary)}' stroke-width='1.5'%3E%3Cpath d='M17.581,11.119 L1.53,11.119 C0.685,11.119 0,11.804 0,12.649 L0,18.044 C0,18.889 0.685,19.574 1.53,19.574 L17.581,19.574 C18.426,19.574 19.111,18.889 19.111,18.044 L19.111,12.649 C19.111,11.804 18.426,11.119 17.581,11.119 Z' id='Stroke-1'%3E%3C/path%3E%3Cpath d='M14.0726,4.517 C14.0726,7.012 12.0506,9.034 9.5556,9.034 C7.0606,9.034 5.0386,7.012 5.0386,4.517 C5.0386,2.022 7.0606,0 9.5556,0 C12.0506,0 14.0726,2.022 14.0726,4.517 Z' id='Stroke-3'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                        @include hover() {
                            background-image: url("data:image/svg+xml,%0A%3Csvg width='21px' height='22px' viewBox='0 0 21 22' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round'%3E%3Cg id='Group-5' transform='translate(1.000000, 1.000000)' stroke='#{color(base, PrimaryHover)}' stroke-width='1.5'%3E%3Cpath d='M17.581,11.119 L1.53,11.119 C0.685,11.119 0,11.804 0,12.649 L0,18.044 C0,18.889 0.685,19.574 1.53,19.574 L17.581,19.574 C18.426,19.574 19.111,18.889 19.111,18.044 L19.111,12.649 C19.111,11.804 18.426,11.119 17.581,11.119 Z' id='Stroke-1'%3E%3C/path%3E%3Cpath d='M14.0726,4.517 C14.0726,7.012 12.0506,9.034 9.5556,9.034 C7.0606,9.034 5.0386,7.012 5.0386,4.517 C5.0386,2.022 7.0606,0 9.5556,0 C12.0506,0 14.0726,2.022 14.0726,4.517 Z' id='Stroke-3'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                        }
                    }
                    &._wishlist {
                        background-image: url("data:image/svg+xml,%0A%3Csvg width='261px' height='228px' viewBox='0 0 261 228' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpath d='M191.406,-0.0003 C172.975,-0.0003 155.645,7.1777 142.607,20.2117 L130.209,32.6117 L117.811,20.2137 C104.776,7.1787 87.446,-0.0003 69.014,-0.0003 C50.583,-0.0003 33.253,7.1777 20.215,20.2117 C7.179,33.2447 0,50.5757 0,69.0137 C0,87.4447 7.178,104.7747 20.212,117.8127 L119.603,217.2037 L130.21,227.8097 L140.816,217.2037 L240.206,117.8137 C253.241,104.7787 260.42,87.4477 260.42,69.0137 C260.42,50.5797 253.241,33.2487 240.206,20.2137 C227.167,7.1777 209.837,-0.0003 191.406,-0.0003 M191.406,14.9997 C205.835,14.9997 219.396,20.6197 229.6,30.8197 C239.803,41.0227 245.42,54.5867 245.42,69.0137 C245.42,83.4397 239.803,97.0037 229.599,107.2067 L130.21,206.5967 L30.82,107.2067 C20.62,97.0037 15,83.4397 15,69.0137 C15,54.5867 20.617,41.0207 30.82,30.8197 C41.023,20.6197 54.587,14.9997 69.014,14.9997 C83.44,14.9997 97.004,20.6197 107.204,30.8197 L130.21,53.8257 L153.212,30.8197 C163.416,20.6197 176.979,14.9997 191.406,14.9997' id='Fill-1' fill='#{color(base, Primary)}'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
                        background-size: contain;
                        @include hover() {
                            background-image: url("data:image/svg+xml,%0A%3Csvg width='261px' height='228px' viewBox='0 0 261 228' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpath d='M191.406,-0.0003 C172.975,-0.0003 155.645,7.1777 142.607,20.2117 L130.209,32.6117 L117.811,20.2137 C104.776,7.1787 87.446,-0.0003 69.014,-0.0003 C50.583,-0.0003 33.253,7.1777 20.215,20.2117 C7.179,33.2447 0,50.5757 0,69.0137 C0,87.4447 7.178,104.7747 20.212,117.8127 L119.603,217.2037 L130.21,227.8097 L140.816,217.2037 L240.206,117.8137 C253.241,104.7787 260.42,87.4477 260.42,69.0137 C260.42,50.5797 253.241,33.2487 240.206,20.2137 C227.167,7.1777 209.837,-0.0003 191.406,-0.0003 M191.406,14.9997 C205.835,14.9997 219.396,20.6197 229.6,30.8197 C239.803,41.0227 245.42,54.5867 245.42,69.0137 C245.42,83.4397 239.803,97.0037 229.599,107.2067 L130.21,206.5967 L30.82,107.2067 C20.62,97.0037 15,83.4397 15,69.0137 C15,54.5867 20.617,41.0207 30.82,30.8197 C41.023,20.6197 54.587,14.9997 69.014,14.9997 C83.44,14.9997 97.004,20.6197 107.204,30.8197 L130.21,53.8257 L153.212,30.8197 C163.416,20.6197 176.979,14.9997 191.406,14.9997' id='Fill-1' fill='#{color(base, PrimaryHover)}'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
                        }
                        &._active {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{color(base, Primary)}' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, Secondary)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z' /%3E%3C/svg%3E%0A");
                        }
                    }
                    &._cart {
                        background-image: url("data:image/svg+xml,%0A%3Csvg width='24px' height='20px' viewBox='0 0 24 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpolygon id='path-a6pc3d_voh-1' points='0 -1.77635684e-15 3.589 -1.77635684e-15 3.589 3.5896 0 3.5896'%3E%3C/polygon%3E%3Cpolygon id='path-a6pc3d_voh-3' points='0 -1.77635684e-15 3.589 -1.77635684e-15 3.589 3.5896 0 3.5896'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group-9' transform='translate(1.000000, 1.000000)'%3E%3Cpolyline id='Stroke-1' stroke='#{color(base, Primary)}' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' points='0 0 2.98 0 6.377 12.476 18.73 11.056 22.065 3.335 9.589 3.335'%3E%3C/polyline%3E%3Cg id='Group-5' transform='translate(4.703000, 15.371600)'%3E%3Cmask id='mask-a6pc3d_voh-2' fill='white'%3E%3Cuse xlink:href='%23path-a6pc3d_voh-1'%3E%3C/use%3E%3C/mask%3E%3Cg id='Clip-4'%3E%3C/g%3E%3Cpath d='M3.589,1.795 C3.589,2.786 2.786,3.59 1.794,3.59 C0.803,3.59 0,2.786 0,1.795 C0,0.804 0.803,-1.77635684e-15 1.794,-1.77635684e-15 C2.786,-1.77635684e-15 3.589,0.804 3.589,1.795' id='Fill-3' fill='#{color(base, Primary)}' mask='url(%23mask-a6pc3d_voh-2)'%3E%3C/path%3E%3C/g%3E%3Cg id='Group-8' transform='translate(16.376100, 15.371600)'%3E%3Cmask id='mask-a6pc3d_voh-4' fill='white'%3E%3Cuse xlink:href='%23path-a6pc3d_voh-3'%3E%3C/use%3E%3C/mask%3E%3Cg id='Clip-7'%3E%3C/g%3E%3Cpath d='M3.589,1.795 C3.589,2.786 2.786,3.59 1.794,3.59 C0.803,3.59 0,2.786 0,1.795 C0,0.804 0.803,-1.77635684e-15 1.794,-1.77635684e-15 C2.786,-1.77635684e-15 3.589,0.804 3.589,1.795' id='Fill-6' fill='#{color(base, Primary)}' mask='url(%23mask-a6pc3d_voh-4)'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                        @include hover() {
                            background-image: url("data:image/svg+xml,%0A%3Csvg width='24px' height='20px' viewBox='0 0 24 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpolygon id='path-a6pc3d_voh-1' points='0 -1.77635684e-15 3.589 -1.77635684e-15 3.589 3.5896 0 3.5896'%3E%3C/polygon%3E%3Cpolygon id='path-a6pc3d_voh-3' points='0 -1.77635684e-15 3.589 -1.77635684e-15 3.589 3.5896 0 3.5896'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group-9' transform='translate(1.000000, 1.000000)'%3E%3Cpolyline id='Stroke-1' stroke='#{color(base, PrimaryHover)}' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' points='0 0 2.98 0 6.377 12.476 18.73 11.056 22.065 3.335 9.589 3.335'%3E%3C/polyline%3E%3Cg id='Group-5' transform='translate(4.703000, 15.371600)'%3E%3Cmask id='mask-a6pc3d_voh-2' fill='white'%3E%3Cuse xlink:href='%23path-a6pc3d_voh-1'%3E%3C/use%3E%3C/mask%3E%3Cg id='Clip-4'%3E%3C/g%3E%3Cpath d='M3.589,1.795 C3.589,2.786 2.786,3.59 1.794,3.59 C0.803,3.59 0,2.786 0,1.795 C0,0.804 0.803,-1.77635684e-15 1.794,-1.77635684e-15 C2.786,-1.77635684e-15 3.589,0.804 3.589,1.795' id='Fill-3' fill='#{color(base, PrimaryHover)}' mask='url(%23mask-a6pc3d_voh-2)'%3E%3C/path%3E%3C/g%3E%3Cg id='Group-8' transform='translate(16.376100, 15.371600)'%3E%3Cmask id='mask-a6pc3d_voh-4' fill='white'%3E%3Cuse xlink:href='%23path-a6pc3d_voh-3'%3E%3C/use%3E%3C/mask%3E%3Cg id='Clip-7'%3E%3C/g%3E%3Cpath d='M3.589,1.795 C3.589,2.786 2.786,3.59 1.794,3.59 C0.803,3.59 0,2.786 0,1.795 C0,0.804 0.803,-1.77635684e-15 1.794,-1.77635684e-15 C2.786,-1.77635684e-15 3.589,0.804 3.589,1.795' id='Fill-6' fill='#{color(base, PrimaryHover)}' mask='url(%23mask-a6pc3d_voh-4)'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                        }
                    }
                    > span {
                        background: color(base, Primary);
                        display: flex;
                        font-size: 1rem;
                        color: color(base, Secondary);
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        width: 1.5rem;
                        height: 1.5rem;
                        position: absolute;
                        top: -.4rem;
                        right: -.4rem;
                        font-weight: 700;
                    }
                }
                ._language {
                    position: relative;
                    > span {
                        cursor: pointer;
                    }
                    ul {
                        display: none;
                        position: absolute;
                        top: 100%;
                        background: color(base, Secondary);
                        left: -1rem;
                        right: -1rem;
                        padding: 1.5rem 0 0;
                        li a {
                            display: block;
                            margin: 0 1rem;
                            img {
                                margin: 0 auto;
                            }
                        }
                    }
                    @include breakpoint(medium) {
                        &:hover > ul {
                            display: block;
                        }
                    }
                }
            }
        }
        &.is-stuck {
            > .grid-container {
                .--logo {
                    @include breakpoint(medium) {
                        flex: 0 0 7.5rem;
                    }
                }
            }
        }
    }
}

.search__modal--overlay {
    transition: opacity .1s ease-in;
    opacity: 0;
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .35);
    z-index: 10;
    .search__modal {
        height: 9.4rem;
        .grid-container {
            height: 100%;
            display: flex;
            align-items: center;
            @include breakpoint(medium) {
                padding: 0 18%;
            }
            .search__container {
                background-color: color(base, White);
                display: flex;
                flex: 1;
                button {
                    width: 5rem;
                    height: 5rem;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 2.2rem;
                    cursor: pointer;
                }
                > button {
                    background-image: url("data:image/svg+xml,%0A%3Csvg width='20px' height='10px' viewBox='0 0 20 10' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group-5'%3E%3Cg id='Group-3' transform='translate(1.323400, 4.489900)' stroke='#{color(base, Secondary)}'%3E%3Cline x1='18.54' y1='0.5' x2='0' y2='0.5' id='Stroke-1'%3E%3C/line%3E%3C/g%3E%3Cpolygon id='Fill-4' fill='#{color(base, Secondary)}' points='5.366 0 0 4.989 5.366 9.978 6.198 9.085 1.791 4.989 6.198 0.894'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                }
                form {
                    display: flex;
                    flex: 1;
                    input {
                        height: 5rem;
                        line-height: 5rem;
                        border: none;
                        flex: 1;
                        &::placeholder {
                            color: color(base, Secondary);
                            opacity: 1;
                        }

                        &:-ms-input-placeholder {
                            color: color(base, Secondary);
                        }

                        &::-ms-input-placeholder {
                            color: color(base, Secondary);
                        }
                    }
                    button {
                        background-image: url("data:image/svg+xml,%0A%3Csvg width='20px' height='21px' viewBox='0 0 20 21' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round'%3E%3Cg id='Group-5' transform='translate(1.000000, 1.000000)' stroke='#{color(base, Secondary)}' stroke-width='1.5'%3E%3Cpath d='M14.77,7.385 C14.77,11.464 11.464,14.77 7.385,14.77 C3.306,14.77 0,11.464 0,7.385 C0,3.306 3.306,0 7.385,0 C11.464,0 14.77,3.306 14.77,7.385 Z' id='Stroke-1'%3E%3C/path%3E%3Cline x1='17.8084' y1='19.2332' x2='11.9594' y2='13.3842' id='Stroke-3'%3E%3C/line%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                    }
                }
            }
        }
    }
}
