@mixin default-title() {
    text-transform: uppercase;
    text-align: center;
    margin: 0 auto 2rem;
    font-size: 1.8rem;
    padding: 0 2rem;
    @include breakpoint(medium) {
        font-size: 2.4rem;
        margin: 0 auto 4rem;
    }
}

.home, .page-builder {
    .breadcrumbs {
        display: none;
    }
    .home__product-list {
        .swiper-wrapper {
            .swiper-slide a {
                display: flex !important;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                ._button {
                    padding: 0 3rem;
                    line-height: 4rem;
                    margin: 1rem 0 0;
                }
                h2 {
                    max-height: 3.8rem;
                    height: 3.8rem;
                    @include breakpoint(small only) {
                        height: 7rem;
                        max-height: 100%;
                    }
                }
            }
        }
    }
    .block__slider {
        @include block-spacing;
        .header {
            position: relative;
            h2 {
                @include default-title;
            }
            .--swiper-pagination {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translatey(-50%);
            }
        }
        .swiper-wrapper {
            margin: 0 -1rem;
            .swiper-slide {
                width: 86%;
                padding: 0 1rem;
                @include breakpoint(medium) {
                    width: calc(25% - 1rem);
                }
            }
        }
        span {
            font-size: 1.5rem;
            position: absolute;
            padding: 2rem;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;
            text-transform: uppercase;
            color: color(base, Primary);
            font-family: $secondary-font;
            line-height: 1;
            text-align: center;
            filter: drop-shadow(0 20px 13px rgb(0 0 0 / 0.03)) drop-shadow(0 8px 5px rgb(0 0 0 / 0.08));
            @include breakpoint(medium) {
                font-size: clamp(1.8rem, 2.4vw, 3.2rem);
            }
        }
        .--first {
            background-color: color(base, Primary);
            line-height: 1.5;
            font-family: $secondary-font;
            padding: 2.5rem;
            display: flex;
            align-items: center;
            font-size: clamp(1.8rem, 2vw, 3.2rem);
            align-items: center;
            aspect-ratio: 1;
        }
        .--slide {
            aspect-ratio: 1;
            display: block;
            @include breakpoint(medium) { 
                display: flex;
            }
            position: relative;
            overflow: hidden;
            @include hover() {
                &:not(.--first) {
                    img {
                        transform: scale(1.1);
                    }
                }
            }
            img {
                transition: transform .15s linear;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .mobile{
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            position: relative;
            gap: 10px;
            padding: 0 0.5rem;
            a {
                position: relative;
                height: -webkit-fill-available;
            }
            h2 {
                grid-column: 1 / -1;
                text-align: center;
                text-transform: uppercase;
                padding: 0 2rem;
                font-size: 1.8rem;
                margin: 0 auto 2rem;
            }
        }
    }
    .home__heritage {
        @include block-spacing;
        background: color(gray, Gray-100);
        padding: 3rem 2rem;
        @include breakpoint(medium) {
            padding: 4rem 2rem;
        }
        > .grid-container {
            display: flex;
            flex-flow: column;
            align-items: center;
            h2 {
                @include default-title;
                @include breakpoint(medium) {
                    max-width: 80rem;
                }
            }
            p {
                @include breakpoint(medium) {
                    margin: 0 auto;
                    font-size: 1.8rem;
                    line-height: 2.4rem;
                    max-width: 80rem;
                }
            }
            a {
                margin: 4rem 0 0;
            }
        }
    }
    .home__block {
        @include block-spacing;
        @include breakpoint(medium) {
            .grid-container {
                display: flex;
            }
        }
        .grid-container {
            justify-content: space-between;
            flex-wrap: wrap;
            picture {
                margin: 0 auto;
            }
            > span, ._content > span {
                display: block;
                text-transform: uppercase;
                font-weight: normal;
                font-size: 1.2rem;
                margin: 0 0 .5rem;
                @include breakpoint(small only) {
                    padding: 0 2rem;
                }
            }
            @include breakpoint(medium) {
                > h2, > span {
                    display: none;
                }
            }
            h2 {
                @include default-title;
                order: 0;
                text-align: left;
                padding: 0;
                @include breakpoint(small only) {
                    padding: 0 2rem;
                }
            }
            img {
                flex: 1;
                width: 100%;
                height: 100%;
                object-fit: cover;
                @include breakpoint(medium) {
                    max-width: 65rem;
                    height: 100%;
                    aspect-ratio: 5/4;
                }
            }
            .image__content {
                position: relative;
                width: 100%;
                @include breakpoint(medium) {
                    order: 1;
                    width: 50%;
                }
                > div {
                    padding: 2rem;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    @include breakpoint(medium) {
                        padding: 4rem;
                    }
                }
            }
            ._content {
                flex: 1;
                padding: 0 5rem;
                @include breakpoint(small only) {
                    padding: 2rem;
                    > h2, > span {
                        display: none;
                    }
                    > div {
                        padding: 0 2rem;
                    }
                }
                @include breakpoint(medium) {
                    margin: 3rem 0 0;
                    p {
                        font-size: 1.8rem;
                        line-height: 2.4rem;
                        font-weight: 100;
                    }
                }
                h3 {
                    color: color(gray, Gray-300);
                    text-transform: uppercase;
                    margin: 3rem 0;
                    @include breakpoint(medium) {
                        margin: 0 0 3rem;
                    }
                }
                a._button {
                    margin: 3rem 0 0;
                }
            }
        }
        &._collection-image-left {
            .image__content{
                order: 0;
            }
        }
        &._alternative {
            background: color(gray, Gray-100);
            padding: 3rem 0 6rem;
            ._content {
                padding: 0;
                position: relative;
                margin: 0;
                @include breakpoint(medium) {
                    margin-left: 0;
                    width: 50%;
                    padding: 0 1rem;
                }
            }
            h2 {
                text-align: center;
                display: block;
                width: 100%;
            }
            p {
                font-weight: 100;
            }
        }
        &._right {
            background: color(gray, Gray-100);
            padding: 2rem 0;
            @include breakpoint(medium) {
                padding: 4rem 0;
                &:not(._alternative) {
                    picture, img {
                        order: 2;
                    }
                }
                &._alternative {
                    .grid-container ._content {
                        order: 2;
                        padding: 0 0 0 10rem;
                    }
                }
            }
        }
    }
    .home__video {
        @include block-spacing;
        h2 {
            @include default-title;
        }
        p {
            margin: 0 auto 2rem;
            padding: 0 2rem;
            @include breakpoint(medium) {
                font-size: 1.8rem;
                line-height: 2.4rem;
                font-weight: 100;
                max-width: 67rem;
                margin: 0 auto 4rem;
                padding: 0;
            }
        }
    }
    .home__sale {
        @include block-spacing;
        background: color(base, Secondary);
        img {
            width: 100%;
        }
        ._content {
            padding: 2rem;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            height: 100%;
            @include breakpoint(medium) {
                padding: 2rem 0;
            }
            h2 {
                color: color(base, Primary);
                text-transform: uppercase;
                font-size: clamp(2rem, 2vw, 3.2rem);
                line-height: normal;
            }
            a {
                margin: 2rem 0 0;
                @include breakpoint(medium) {
                    margin: 4rem 0 0;
                }
            }
        }
    }
}

.page-builder {
    .breadcrumbs {
        display: block;
        @include breakpoint(medium) {
            margin: 0 0 -8rem;
        }
    }
}
