.store__locator {
    margin: 3rem 0 0;
    ._content {
        h1 {
            @include head-font;
            font-size: 2.2rem;
            text-transform: uppercase;
            line-height: normal;
            margin: 0 0 2rem;
        }
        p {
            margin: 0 0 2rem;
        }
    }
    .vue-map-container {
        width: 100%;
        height: 500px;
    }
}
