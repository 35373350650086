.--related {
    margin: 4rem auto 0;
    @include breakpoint(medium) {
        margin: 8rem auto 0;
    }
    .--header {
        position: relative;
        .--swiper-pagination {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    .swiper-container {
        margin: 0 -1rem;
        .swiper-slide {
            li {
                margin: 0 1rem;
                .add-to-cart__container {
                    button, a {
                        line-height: 4rem !important;
                    }
                }
            }
            width: 80%;
            @include breakpoint(medium) {
                width: 25%;
            }
        }
    }
}
