._pagination {
    margin: 0 0 4rem;
    > span {
        display: block;
        text-align: center;
        font-size: 1.5rem;
        line-height: normal;
        margin: 2rem 0;
        font-weight: 100;
    }
    ul {
        display: flex;
        justify-content: center;
        li {
            ._button {
                cursor: pointer;
                display: block;
                width: 3.2rem;
                height: 3.2rem;
                line-height: 3.2rem;
                font-size: 1.5rem;
                margin: 0 .5rem;
                text-align: center;
                background-repeat: no-repeat;
                background-position: center;
                padding: 0 .3rem 0 .3rem;
                font-family: $default-font;
                background-color: color(grey, Gray-100);
                color: color(base, Secondary);
                border: none;
                @include breakpoint(medium) {
                    &:hover {
                        background-color: color(grey, Gray-200);
                    }
                }
                &._next {
                    background-color: transparent;
                    background-image: url("data:image/svg+xml,%0A%3Csvg width='9px' height='14px' viewBox='0 0 9 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline id='Stroke-1' stroke='#{color(base, Secondary)}' stroke-width='2' points='1.005 1 6.891 6.886 1 12.776'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
                }
                &._prev {
                    background-color: transparent;
                    background-image: url("data:image/svg+xml,%0A%3Csvg width='9px' height='14px' viewBox='0 0 9 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline id='Stroke-1' stroke='#{color(base, Secondary)}' stroke-width='2' transform='translate(4.945500, 6.888000) rotate(-180.000000) translate(-4.945500, -6.888000) ' points='2.005 1 7.891 6.886 2 12.776'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
                }
                &._current {
                    cursor: default;
                    pointer-events: none;
                    background-color: color(base, Secondary);
                    color: color(base, Primary);
                }
            }
        }
    }
}
